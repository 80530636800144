import { IBuilder } from "../../@types/interface/Builder.interface";
import { ICustomer } from "../../@types/interface/Customer.interface";
import * as XLSX from "xlsx";
import { IProject } from "../../@types/interface/Projects";

// type guard for Customer
const isCustomer = (data: any): data is ICustomer => {
  return (data as ICustomer).full_name !== undefined;
};

//type guard for Builder
const isBuilder = (data: any): data is IBuilder => {
  return (data as IBuilder).builder_name !== undefined;
};

//type guard for Project
const isProject = (data: any): data is IProject => {
  return (data as IProject).project_name !== undefined;
};

export const downloadExcel = (
  rawData: ICustomer[] | IBuilder[] | IProject[],
  dataType: "customer" | "builder" | "project"
) => {
  console.log("yo", rawData);
  const processedData = rawData.map((data) => {
    if (isCustomer(data)) {
      const { full_name, email, gender, phone, address, state } =
        data as ICustomer;
      return {
        "Full Name": full_name,
        Email: email,
        Gender: gender || "",
        "Phone Number": phone || "",
        Address: address || "",
        State: state || "",
      };
    } else if (isBuilder(data)) {
      const {
        builder_name,
        builder_number,
        cin_number,
        number_projects,
        contact_first_name,
        contact_last_name,
        contact_phone_number,
        email,
        GST_number,
        PAN,
      } = data as IBuilder;
      return {
        "Builder's Name": builder_name,
        "Builder Number": builder_number,
        "CIN Number": cin_number,
        "Number of Projects": number_projects,
        "Contact First Name": contact_first_name,
        "Contact Last Name": contact_last_name,
        "Contact Phone Number": contact_phone_number,
        Email: email,
        "GST Number": GST_number,
        "PAN Number": PAN,
      };
    } else if (isProject(data)) {
      const {
        project_name,
        state,
        formatted_address,
        price_per_sq,
        total_sq_feet,
        description,
        average_rating,
        no_of_ratings,
      } = data as IProject;
      return {
        "Project Name": project_name,
        State: state,
        "Formatted Address": formatted_address,
        "Price Per Sq": price_per_sq,
        "Total Sq Feet": total_sq_feet,
        Description: description,
        "Average Rating":
          average_rating || average_rating === 0 ? average_rating : "",
        "Number of Ratings":
          no_of_ratings || no_of_ratings === 0 ? no_of_ratings : "",
      };
    }
  });
  return () => {
    console.log("yo", processedData);
    if (processedData.length === 0) return alert("No data to download");
    const ws = XLSX.utils.json_to_sheet(processedData);
    const wb = XLSX.utils.book_new();
    const fileName =
      dataType === "customer"
        ? "CustomerData.xlsx"
        : dataType === "builder"
        ? "BuilderData.xlsx"
        : dataType === "project"
        ? "ProjectData.xlsx"
        : "Data.xlsx";

    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, fileName);
  };
};
