import React, { useContext, useEffect } from 'react'
import UIContext from '../../../contexts/uiContext/UIContext'
import DataGrid from '../../shared/dataGrid/DataGrid'

import { AgGridReact } from 'ag-grid-react'
import DataCellRenderer from '../../shared/cellRenderer/DataCellRenderer'
import SeeDetailsCellRenderer from '../admin/plots/seeDetailsCellRenderer/SeeDetailsCellRenderer'
import { ReportsColDefs } from './reportColDefs/ReportColDefs'
import { REPORT_TYPES } from '../../../constants/reportTypes/ReportTypes'






const Reports = () => {
const {setDashboardHeader} = useContext(UIContext)


const rowdata : any = [{
  report_type : 'City Wise Report',
  description : "Demo report",
  

},
{
  report_type : REPORT_TYPES.project_report,
  description : "Demo report",
 

},
{
  report_type : 'Builder Wise Report',
  description : "Demo report",
 

}]
  useEffect(() => {
  setDashboardHeader('All Reports')
  }, [setDashboardHeader])
  return (
    <div>
      {/* <DataGrid rowData={[]} colDefs={[ReportsColDefs]} key={0}/> */}
      <div className="ag-theme-alpine" style={{ height: 300}}>
      <AgGridReact
        animateRows={true}
        rowSelection="multiple"
        rowData={rowdata}
        columnDefs={ReportsColDefs}
        
      />
    </div>
    </div>
  )
}

export default Reports
