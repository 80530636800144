import { request } from "../api";
import { headers } from "../../../configs/config";
import { MESSAGE } from "../../../constants/api/message";

const { get } = request;
const initialRoute = "bookings";

export const getBookingDetails = async (filterQuery: any) => {
  try {
    const endpoint = `${initialRoute}/get-booking-details`;
    const response = await get(
      endpoint,
      {
        ...headers,
      },
      filterQuery
    );
    if (response) {
      const {
        data: { message },
      } = response;
      if (message === MESSAGE.get.succ) {
        const {
          data: { result },
        } = response;
        return result;
      }
    }
    throw new Error();
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getEnquiryDetails = async (filterQuery: any) => {
  try {
    const endpoint = `${initialRoute}/get-enqury-details`;
    const response = await get(
      endpoint,
      {
        ...headers,
      },
      filterQuery
    );
    if (response) {
      const {
        data: { message },
      } = response;
      if (message === MESSAGE.get.succ) {
        const {
          data: { result },
        } = response;
        return result;
      }
    }
    throw new Error();
  } catch (error) {
    console.log(error);
    throw error;
  }
};
