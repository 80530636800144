import React, { useContext, useEffect } from 'react'
import UIContext from '../../../../contexts/uiContext/UIContext'

const LeadStatusDetails = () => {
    const {setDashboardHeader} = useContext(UIContext)

    useEffect(() => {
        setDashboardHeader('Lead status')
    }, [setDashboardHeader])
  return (
    <div>LeadStatusDetails</div>
  )
}

export default LeadStatusDetails