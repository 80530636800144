import React, { useContext, useState } from "react";
import AuthContext from "../../../../contexts/authContext/authContext";
import { FaUser } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  TextField,
  Button,
  Avatar,
  Typography,
} from "@mui/material";
import Topbar from "../../../layout/topbar/Topbar";
import Sidebar from "../../../shared/sidebar/Sidebar";
import AdminSidebar from "../../../shared/sidebar/AdminSidebar";
import OwnerSidebar from "../../../shared/sidebar/OwnerSidebar";
import ResellerSidebar from "../../../shared/sidebar/ResellerSidebar";
import MarketerSidebar from "../../../shared/sidebar/MarketerSidebar";
import sizeConfigs from "../../../../configs/sizeConfigs";

const Profile = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({ ...user });
  const [isEditing, setIsEditing] = useState(false);
  const location = useLocation();
  const sidebarWidth = sizeConfigs.sidebar.width;

  const isOnAdminPage = location.pathname.startsWith("/admin");
  const isOnOwnerPage = location.pathname.startsWith("/landlord");
  const isOnRetailerPage = location.pathname.startsWith("/reseller");
  const isOnMarketerPage = location.pathname.startsWith("/marketer");

  const renderSidebar = () => {
    if (isOnAdminPage) {
      return <AdminSidebar toggleSidebar={toggleSidebar} opens={sidebarOpen} />;
    } else if (isOnOwnerPage) {
      return <OwnerSidebar toggleSidebar={toggleSidebar} opens={sidebarOpen} />;
    } else if (isOnRetailerPage) {
      return (
        <ResellerSidebar toggleSidebar={toggleSidebar} opens={sidebarOpen} />
      );
    } else if (isOnMarketerPage) {
      return (
        <MarketerSidebar toggleSidebar={toggleSidebar} opens={sidebarOpen} />
      );
    } else {
      return <Sidebar toggleSidebar={toggleSidebar} opens={sidebarOpen} />;
    }
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
    // Add save functionality here
    console.log("Saved data:", editUser);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  return (
    <div>
      <Topbar toggleSidebar={toggleSidebar} sidebarOpen={sidebarOpen} />
      <Box
        component="nav"
        sx={{
          width: sidebarOpen ? sidebarWidth : 0,
          flexShrink: 0,
          transition: "width 0.2s ease-in-out",
        }}
      >
        {renderSidebar()}
      </Box>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "16px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#ffffff",
              boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
              borderRadius: "12px",
              padding: "32px",
              maxWidth: "700px",
              width: "100%",
            }}
          >
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src="https://static.vecteezy.com/system/resources/previews/035/857/643/non_2x/3d-simple-user-icon-isolated-render-profile-photo-symbol-ui-avatar-sign-person-or-people-gui-element-realistic-illustration-vector.jpg"
                  alt="Profile"
                  sx={{
                    width: 128,
                    height: 128,
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "#333333" }}
                    >
                      Full Name
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="full_name"
                      value={editUser.full_name}
                      onChange={handleChange}
                      InputProps={{ readOnly: !isEditing }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "#333333" }}
                    >
                      Email
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="email"
                      value={editUser.email}
                      onChange={handleChange}
                      InputProps={{ readOnly: !isEditing }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "#333333" }}
                    >
                      Phone Number
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="phone_number"
                      value={editUser.phone_number}
                      onChange={handleChange}
                      InputProps={{ readOnly: !isEditing }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "#333333" }}
                    >
                      Role
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="role"
                      value={editUser.role}
                      onChange={handleChange}
                      InputProps={{ readOnly: !isEditing }}
                    />
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: "16px",
                  }}
                >
                  {isEditing ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSave}
                      sx={{
                        marginRight: "8px",
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleEdit}
                      sx={{
                        marginRight: "8px",
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setIsEditing(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Profile;
