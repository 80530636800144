import React, { useState } from "react";
import "./phoneLogin.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import loginImgae from "../../../../assets/images/Login-amico.png";
import { api } from "../../../../utils/api";
import { useNavigate } from "react-router-dom";

const PhoneLogin = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [ valid, setValid] = useState(true)
  const [otpCheck, setOtpCheck] = useState("");
  const navigate = useNavigate();
  // Function to handle input change
  const handlePhoneChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    
    setPhoneNumber(event.target.value);
    
  };

  const validatePhoneNumber = () => {
    
  }
  const handleRequestOTP = async () => {
    try {
      const response = await api.auth.getOtp({ phone_no: phoneNumber });
      console.log("Check otp", response);
      if (response) {
        setOtpCheck(response);
        navigate("/admin/verify-login", { state: { otpCheck: response } });
      }
    } catch (error) {
      console.error("Error requesting OTP:", error);
    }
  };

  return (
    <>
      <section className="login">
        <div className="login_box">
          <div className="left">
            <div className="contact">
              <AccountCircleIcon
                style={{
                  marginTop: "5px",
                  width: "48px",
                  height: "48px",
                  marginBottom: 10,
                }}
              />
              <h3>Login</h3>
              <img
                src={loginImgae}
                alt=""
                style={{
                  backgroundSize: "cover",
                  height: "180px",
                  width: "180px",
                }}
              />
              <div className="phone-container">
                <div className="flag-container">
                  <img
                    src="https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg"
                    alt="Indian Flag"
                    className="flag"
                  />
                  <span className="country-code">+91</span>
                </div>
                <input
                  type="tel"
                  placeholder="PHONE NUMBER"
                  className="phone-input"
                  value={phoneNumber} // Bind value to state
                  onChange={handlePhoneChange} // Handle input change
                />
              </div>
              <button className="submit" onClick={handleRequestOTP}>
                Verify
              </button>
            </div>
          </div>
          <div className="right">
            <div className="right-text">
              <h2>FIND MY HOUSE</h2>
              <h5>A PLOT SEARCHING AGENCY</h5>
            </div>
            <div className="right-inductor">{/* Add your content here */}</div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PhoneLogin;
