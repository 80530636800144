import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Modal, Button, TextField, Tabs, Tab, Box, IconButton } from "@mui/material";
import { IAdditionalInterfaceProps } from "../../../../../../@types/interface/additionalInfor.interface";
import FileUploadFields from "../../../../../shared/fileUpload/FileUpload";
import DeleteIcon from "@mui/icons-material/Delete";


// Helper component for TabPanel
function TabPanel(props: { children?: React.ReactNode; index: number; value: number }) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const AdditionalInfo: React.FC<IAdditionalInterfaceProps> = ({
  broucherFile,
  setBroucherFile,
  videoFile,
  setVideoFile,
  ytVideoUrl,
  setYtVideoUrl
}) => {
  const [videoPreview, setVideoPreview] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [tempYtUrl, setTempYtUrl] = useState<string>("");
  const [tabValue, setTabValue] = useState<number>(0); // For switching between tabs

  // Function to handle modal open and close
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  // Function to handle saving YouTube video link or file upload
  const handleSave = () => {
    if (tabValue === 0 && videoFile) {
      const videoUrl = URL.createObjectURL(videoFile);
      setVideoPreview(videoUrl);
      console.log(videoUrl)
    } else if (tabValue === 1 && tempYtUrl) {
      setYtVideoUrl(tempYtUrl);
      setTempYtUrl("");
    }
    handleClose();
  };

  // Function to handle tab change
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
 // Function to remove video or YouTube link
 const handleRemoveVideo = () => {
  setVideoFile(null);
  setYtVideoUrl(null);
  setVideoPreview(null);
};

useEffect(() => {
  handleSave()
}, [videoFile])


  return (
    <Accordion defaultExpanded style={{ display: "block", marginTop: "20px", paddingBottom: 60 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <h3>Additional Info</h3>
      </AccordionSummary>
      <AccordionDetails>
        {/* Brochure PDF Upload */}
        <FileUploadFields
          label="Brochure Pdf"
          file={broucherFile}
          setFile={setBroucherFile}
          onChange={(file: File) => {
            console.log("Brochure file changed:", file);
            setBroucherFile(file);
          }}
          accept=".pdf"
        />

        {/* Video/YouTube Selection Modal Trigger */}
        <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <label className="labels" style={{ paddingLeft: 30 }}>
        Video
      </label>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          style={{ marginTop: "20px" ,marginRight: 60,}}
           className="btn"
           disabled={videoPreview || ytVideoUrl}
        >
          Select Video
        </Button>
</div>
      
        {/* Video Preview Section */}
        {videoPreview && (
          <div style={{ marginTop: "20px", position: "relative" ,justifyContent:"end",alignItems:"end",width:400}}>
            <h4>Video Preview:</h4>
            <video
              width="320"
              height="240"
              controls
              src={videoPreview}
              style={{ border: "1px solid #ccc", padding: "10px" }}
            >
              Your browser does not support the video tag.
            </video>
            <IconButton
              onClick={handleRemoveVideo}
              style={{ position: "absolute", top: 0, right: 50 ,fontSize:18,borderRadius:5 }}
            >
              <DeleteIcon color="error" />
              Delete
            </IconButton>
          </div>
        )}

        {/* YouTube Video Preview */}
        {ytVideoUrl && (
          <div style={{ marginTop: "20px", position: "relative" ,width:400}}>
            <h4>Video Preview:</h4>
            <iframe
              width="320"
              height="240"
              src={`https://www.youtube.com/embed/${ytVideoUrl.split("v=")[1]}`}
              title="YouTube video preview"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ border: "1px solid #ccc", padding: "10px" }}
            />
           <IconButton
              onClick={handleRemoveVideo}
              style={{ position: "absolute", top: 0, right: 50 ,fontSize:18,borderRadius:5 }}
            >
              <DeleteIcon color="error" />
              Delete
            </IconButton>
          </div>
        )}
        {/* Modal for Video/YouTube Selection */}
        <Modal open={modalOpen} onClose={handleClose}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              boxShadow: "0px 0px 15px rgba(0,0,0,0.1)",
              width: "400px"
            }}
          >
            <h3>Select Video</h3>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="video tabs">
              <Tab label="Upload Video" />
              <Tab label="YouTube URL" />
            </Tabs>

            <TabPanel value={tabValue} index={0}>
              {/* Video Upload Tab */}
              <FileUploadFields
                label="Video"
                file={videoFile}
                setFile={setVideoFile}
                onChange={(file: File) => {
                  console.log("Video file changed:", file);
                  handleSave()
                }}
                accept=".mp3,.mp4,video/*"
              />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              {/* YouTube URL Input Tab */}
              <TextField
                label="YouTube URL"
                variant="outlined"
                fullWidth
                value={tempYtUrl}
                onChange={(e) => setTempYtUrl(e.target.value)}
                style={{ marginBottom: "20px" }}
              />
               <Button variant="contained" color="primary"   className="btn" onClick={handleSave}>
              Submit
            </Button>
            </TabPanel>

           
          </div>
        </Modal>
      </AccordionDetails>
    </Accordion>
  );
};

export default AdditionalInfo;
