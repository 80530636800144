import { useState } from "react";
import Calendar from "react-calendar";

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

const SmallCalendar = () => {
  const [value, onChange] = useState<Value>(new Date());

 
  return (
    <div>
      <div style={{ width: "250px", height: "auto" }}>
        <Calendar onChange={onChange} value={value} calendarType="hebrew" />
      </div>
    </div>
  );
};

export default SmallCalendar;
