import ReportCellRenderer from "../reportCellRenderer/ReportCellRenderer";


export const ReportsColDefs =[
    {field: "report_type", headerName: "Report Type", suppressSizeToFit:true, },
    {field: "description", headerName: "Description",suppressSizeToFit:true},
    
    {field: "last_created_by", headerName: "Created By", suppressSizeToFit:true},
    {field: "last_created_on", headerName: "Created on", suppressSizeToFit:true},
    
    {field: "last_edited_on", headerName: "Last edited on", suppressSizeToFit:true},
    {field: "action", headerName: "Action", cellRenderer : ReportCellRenderer},
   
]