import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  TextField,
  styled,
} from "@mui/material";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import React, { useCallback, useContext, useEffect, useState } from "react";
import ProjectFormTextField from "../../../../shared/formTextField/projectFormTextField/ProjectFormTextField";
import "./bank.css";
import UIContext from "../../../../../contexts/uiContext/UIContext";
import { IBankContacts } from "../../../../../@types/interface/bankContact";
import { defaultBankForm } from "../../../../../defaultValue/bankForm/BankForm";
import BankDetails from "../BankDetails";
import { api } from "../../../../../utils/api";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const AddBank = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const [uploadedLogo, setUploadedLogo] = useState<File | null>(null);
  const [bankDetails, setBankDetails] =
    useState<IBankContacts>(defaultBankForm);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name, value },
    } = event;
    setBankDetails(Object.assign({}, bankDetails, { [name]: value }));
  }, [bankDetails]);
  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      formData.append("bankContactDetails", JSON.stringify(bankDetails));
      if (uploadedLogo) {
        formData.append("bank_logo", uploadedLogo);
      }
      const response = await api.bank.addBank(formData);
      if (response) {
        alert("bank details added")

      }
      if (!response) {
        throw new Error(`API request failed with status ${response.status}`);
      }
      setBankDetails(defaultBankForm);
    } catch (error) {
      console.log("error while data adding");
    }
  };
  useEffect(() => {
    setDashboardHeader("Add Bank Details");
  }, [setDashboardHeader]);
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Accordion defaultExpanded>
          <AccordionSummary aria-controls="panel3-content" id="panel3-header">
            <h3>Bank Details</h3>
          </AccordionSummary>
          <AccordionDetails>
            <div className="input-field-container">
              <label>Bank Name:</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                style={{ width: "55%" }}
                value={bankDetails.bank_name}
                name="bank_name"
                onChange={handleChange}
              />
            </div>
            <div className="input-field-container">
              <label>Contact Person Name:</label>
              <TextField
                id="outlined-basic"
                variant="outlined"
                style={{ width: "55%" }}
                value={bankDetails.contact_person_name}
                name="contact_person_name"
                onChange={handleChange}
              />
            </div>
            <div className="input-field-container">
              <label>Bank Logo:</label>
              <div className="logo-container">
                <Button
                  style={{
                    marginTop: 20,
                    marginBottom: 10,
                    backgroundColor: "#49BB43",
                  }}
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  className="btn"
                  startIcon={<CloudUploadOutlinedIcon />}
                >
                  Upload Bank Logo
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => {
                      const file = event.target.files?.[0];
                      if (file) {
                        setUploadedLogo(file);
                      }
                    }}
                    required
                  />
                </Button>

                {uploadedLogo && (
                  <Chip
                    label={uploadedLogo.name}
                    onDelete={() => setUploadedLogo(null)}
                    variant="outlined"
                    sx={{ marginTop: 1 }}
                  />
                )}
              </div>
            </div>
            <Button
              type="submit"
              variant="contained"
              className="btn"
              endIcon={<SendOutlinedIcon />}
            >
              Submit
            </Button>
          </AccordionDetails>
        </Accordion>
      </div>
    </form>
  );
};

export default AddBank;
