import { Accordion, AccordionDetails, AccordionSummary, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IEnquiryProps } from "../../../../../../@types/props/enquiry.props";

const CustomerEnquiry = ({enquiry}:IEnquiryProps) => {
  const [ readOnly , setReadOnly] = useState<boolean>(true)
  const handleClick = () => {
    setReadOnly(!readOnly)
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "right",
        }}
      >
        <Button
          className="btn"
          variant="contained"
          onClick={handleClick}
          endIcon={<EditOutlinedIcon />}
        >
          Edit
        </Button>
      </div>
      <Accordion
        defaultExpanded
        style={{ display: "block", marginTop: "20px" }}
        elevation={4}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        ></AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: "flex",
              alignItems: "left",
              justifyContent: "space-between",
            }}
          >
            <label style={{ fontSize: "16px", marginLeft: "40px" }}>
              Customer Name:
            </label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              defaultValue={enquiry.customer_name}
              sx={{
                width: "60%",
                marginRight: "40px",
                marginBottom: "10px",
              }}
              inputProps={{ readOnly: readOnly }}
              required
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "left",
              justifyContent: "space-between",
            }}
          >
            <label style={{ fontSize: "16px", marginLeft: "40px" }}>
              Customer Contact Number:
            </label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              name="cusomer_phone"
              value={enquiry.customer_phone || "N/A"}
              sx={{
                width: "60%",
                marginRight: "40px",
                marginBottom: "10px",
              }}
              inputProps={{ readOnly: readOnly }}
              required
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "left",
              justifyContent: "space-between",
            }}
          >
            <label style={{ fontSize: "16px", marginLeft: "40px" }}>
              Customer Budget:
            </label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              name="cusomer_budget"
              value={enquiry.customer_budget || "N/A"}
              sx={{
                width: "60%",
                marginRight: "40px",
                marginBottom: "10px",
              }}
              inputProps={{ readOnly: readOnly }}
              required
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "left",
              justifyContent: "space-between",
            }}
          >
            <label style={{ fontSize: "16px", marginLeft: "40px" }}>
              Desired Facing:
            </label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              name="desired_facing"
              value={enquiry.desired_facing || "N/A"}
              sx={{
                width: "60%",
                marginRight: "40px",
                marginBottom: "10px",
              }}
              inputProps={{ readOnly: readOnly }}
              required
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CustomerEnquiry;
