import { Button } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UIContext from "../../../../contexts/uiContext/UIContext";
import { IBankContacts } from "../../../../@types/interface/bankContact";
import AddIcon from "@mui/icons-material/Add";
import { api } from "../../../../utils/api";
import NameCellRenderer from "../../allCustomers/nameCellRenderer/NameCellRenderer";

const BankDetails = () => {
  const navigate = useNavigate();
  const { setDashboardHeader } = useContext(UIContext);
  const [bankData, setBankData] = useState<IBankContacts[]>([]);

  const bankcolumn: any = [
    {
      field: "bank_name",
      headerName: "Bank Name",
      
    },

    { field: "contact_person_name", headerName: "Contact person name" },

    { field: "bank_logo", headerName: "Bank logo" },
    { field: "phone_number", headerName: "Contact Person Phone No." },
  ];

  const getBankDetails = useCallback(async (filterQuery: any) => {
    try {
      const filter = { ...filterQuery };
      const response = await api.bank.getBankContacts(filter);
      if (response) {
        setBankData(response);
        console.log("response", response);
      }
    } catch (error) {
      throw new Error("error while data fetching");
    }
  }, []);

  const handleNavigateToAddBank = () => {
    navigate("/permission/add-bank");
  };

  useEffect(() => {
    getBankDetails({});
  }, [getBankDetails]);

  useEffect(() => {
    setDashboardHeader("Bank Details");
  }, [setDashboardHeader]);
  return (
    <div>
      <div className="add-user-btn">
        <Button
          className="btn"
          variant="contained"
          onClick={handleNavigateToAddBank}
          endIcon={<AddIcon />}
        >
          Add Bank
        </Button>
      </div>
      <div className="ag-theme-alpine" style={{ height: 300 }}>
        <AgGridReact rowData={bankData} columnDefs={bankcolumn} />
      </div>
    </div>
  );
};

export default BankDetails;
