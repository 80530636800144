import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import UIContext from "../../../../../contexts/uiContext/UIContext";
import "./bannerform.css";
import { OPTIONS } from "../../../../../constants/adFor/Adfor";

import { BannerFor } from "../../../../../@types/interface/banner.interface";
import { api } from "../../../../../utils/api";
import AuthContext from "../../../../../contexts/authContext/authContext";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddBanner = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [advertisementDetails, setAdvertisementDetails] = useState<BannerFor>({
    banner_details: {
      redirection_link: "",
      sequence: 0,
      uploaded_for: "",
      uploaded_by: "",
      caption: "",
    },
    options: "",
  });
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [builderList, setBuilderList] = useState<string[]>([]);
  const [projectList, setProjectList] = useState<string[]>([]);
  const { user } = useContext(AuthContext);
  // console.log(user, "User details");

  const handleChange = async (event: SelectChangeEvent<any>) => {
    const { name, value } = event.target;
    console.log("Name", name, "Value", value);

    if (!name) return;

    if (name === "options") {
      setAdvertisementDetails((prevDetails) => ({
        ...prevDetails,
        options: value as string,
        banner_details: {
          ...prevDetails.banner_details,
          uploaded_for: value,
          uploaded_by: user?._id as string,
          // uploaded_by: user?._id,
        },
      }));
      // setAdvertisementDetails((prevDetails: any) => ({
      //   ...prevDetails,
      //   [name]: value,
      // }));
      // console.log("AdvertisementDetails--->", prevDetails)

      if (value === OPTIONS.builder) {
        try {
          const response = await api.builder.getBuilderList({});
          if (response && response.length > 0) {
            console.log("BuilderList--->", response);
            setBuilderList(response);
          }
        } catch (error) {
          console.error("Error while fetching data:", error);
        }
      }
      //getprojects
      else if (value === OPTIONS.project) {
        try {
          const response = await api.project.getProjectList({});
          if (response && response.length > 0) {
            console.log("ProjectList--->", response);
            setProjectList(response);
          }
        } catch (error) {
          console.error("Error while fetching data:", error);
        }
      }
    } else if (name === "builder" || name === "project") {
      const selectedProject: any = projectList.find(
        (project: any) => project.project_name === value
      );
      const projectId = selectedProject ? selectedProject._id : "";
      console.log("Selected Project:", selectedProject);
      console.log("Project ID:", projectId);

      const updatedAdvertisementDetails = {
        ...advertisementDetails,
        [name]: value,
        banner_details: {
          ...advertisementDetails.banner_details,
          redirection_link:
            name === "project"
              ? `http://localhost:5173/project-details?pid=${projectId}`
              : advertisementDetails.banner_details.redirection_link,
        },
      };
      console.log(
        "Updated Advertisement Details:",
        updatedAdvertisementDetails
      );

      setAdvertisementDetails(updatedAdvertisementDetails);
    } else if (name === "sequence") {
      setAdvertisementDetails((prevDetails) => ({
        ...prevDetails,
        banner_details: {
          ...prevDetails.banner_details,
          [name]: parseInt(value, 10),
        },
      }));
    } else {
      setAdvertisementDetails((prevDetails) => ({
        ...prevDetails,

        [name]: value,
      }));
    }
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setAdvertisementDetails((prevDetails) => ({
      ...prevDetails,
      banner_details: {
        ...prevDetails.banner_details,
        [name]: value,
      },
    }));
  };

  useEffect(() => {
    setDashboardHeader("Advertisement  Details");
  }, [setDashboardHeader]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    try {
      const formData = new FormData();
      console.log(advertisementDetails, "Banner Details");
      formData.append(
        "bannerDetails",
        JSON.stringify(advertisementDetails.banner_details)
      );
      if (uploadedFile) {
        formData.append("banner", uploadedFile);
      }
      const response = await api.banner.createBanner(formData);
      if (response) {
        console.log("Response of the post----->Banner thing", response);
      } else {
        throw new Error(`API request failed with status ${response.status}`);
      }
      setHasSubmitted(true);
    } catch (error) {
      console.error("Error while adding", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <h3>Banner Details</h3>
        <Accordion defaultExpanded style={{ marginTop: "20px" }}>
          <AccordionDetails>
            <div className="details" style={{ marginTop: "20px" }}>
              <div className="fields">
                <label>Advertisement For:</label>

                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label"></InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      style={{ width: "600px" }}
                      name="options"
                      value={advertisementDetails.options}
                      onChange={handleChange}
                      disabled={hasSubmitted}
                    >
                      <MenuItem value={OPTIONS.project}>Project</MenuItem>
                      <MenuItem value={OPTIONS.builder}>Builder</MenuItem>
                      <MenuItem value={OPTIONS.others}>Others</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              {/* for project */}
              {advertisementDetails.options === OPTIONS.project &&
              !hasSubmitted ? (
                <div className="fields">
                  <label>Select Project:</label>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label"></InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        style={{ width: "600px" }}
                        value={advertisementDetails.project} // Assuming you have builder property in advertisementDetails
                        onChange={handleChange}
                        name="project"
                      >
                        {projectList.map((project: any) => (
                          <MenuItem
                            key={project._id}
                            value={project.project_name}
                          >
                            {project.project_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              ) : (
                ""
              )}

              {/* for builder */}
              {advertisementDetails.options === OPTIONS.builder &&
              !hasSubmitted ? (
                <div className="fields">
                  <label>Select Builder:</label>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label"></InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        style={{ width: "600px" }}
                        value={advertisementDetails.builder} // Assuming you have builder property in advertisementDetails
                        onChange={handleChange}
                        name="builder"
                      >
                        {builderList.map((builder: any) => (
                          <MenuItem
                            key={builder._id}
                            value={builder.builder_name}
                          >
                            {builder.builder_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              ) : (
                ""
              )}

              {/* in case of other companies  */}
              {advertisementDetails.options === OPTIONS.others &&
              !hasSubmitted ? (
                <div className="fields">
                  <label>Redirection Link:</label>
                  <TextField
                    className="input-fields"
                    variant="outlined"
                    style={{ width: "600px" }}
                  />
                </div>
              ) : (
                ""
              )}

              <div className="fields">
                <label>Sequence :</label>
                <div>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Sequence
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={advertisementDetails.banner_details.sequence.toString()}
                        label="sequence"
                        onChange={handleChange}
                        name="sequence"
                      >
                        <MenuItem value={0}>0</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </div>

              <div className="fields">
                <label>Banner Photo:</label>
                <div className="banner-photo-btn">
                  <Button
                    style={{ marginBottom: "20px", backgroundColor: "#49BB43" }}
                    component="label"
                    role={undefined}
                    variant="contained"
                    // tabIndex={-1}
                  >
                    Upload Banner Image
                    <VisuallyHiddenInput
                      type="file"
                      onChange={(event) => {
                        const file = event.target.files?.[0];
                        if (file) {
                          setUploadedFile(file);
                        }
                      }}
                      required
                    />
                  </Button>

                  {uploadedFile && (
                    <Chip
                      label={uploadedFile.name}
                      onDelete={() => setUploadedFile(null)}
                      variant="outlined"
                      sx={{ marginBottom: "20px" }}
                    />
                  )}
                </div>
              </div>

              <div className="fields">
                <label>Caption for Advertisement:</label>
                <TextField
                  className="input-fields"
                  id="outlined-basic"
                  label="if any(optional)"
                  variant="outlined"
                  style={{ width: "600px" }}
                  value={advertisementDetails.banner_details.caption}
                  onChange={handleInputChange}
                  name="caption"
                />
              </div>
            </div>

            <Button type="submit" variant="contained" className="submit-btn">
              SUBMIT
            </Button>
          </AccordionDetails>
        </Accordion>
      </div>
    </form>
  );
};

export default AddBanner;
