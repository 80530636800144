import DataGrid from "../../shared/dataGrid/DataGrid";
import { PlotsDefs } from "../../../constants/plots/plotsDefs";
import "./project.css";
import { useCallback, useContext, useEffect, useState } from "react";
import { api } from "../../../utils/api";
import { IProject } from "../../../@types/interface/Projects";
import UIContext from "../../../contexts/uiContext/UIContext";
import { Box, Button, Tab, Tabs } from "@mui/material";
import a11yProps from "../../../constants/tabPanelProps/ayProps";
import TabPanel from "../../shared/tabPanel/TabPanel";
import { PROJECT_STATUS } from "../../../constants/projectStatus/ProjectStatus";
import { get } from "http";
import Download from "../../shared/downloadbtn/Download";
import { downloadExcel } from "../../../utils/commonFunction/downloadExcel";
import BasicPagination from "../../shared/basicPagination/BasicPagination";
import { IPagination } from "../../../@types/interface/pagination";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../contexts/authContext/authContext";
import { ROLES } from "../../../constants/roles/Roles";
import BuilderContext from "../../../contexts/builderContext/BuilderContext";
import { UpcomingProjectDefs } from "../../../constants/upcomingProjects/upcomingProjectDefs";
import UpcomingProjectModal from "./projectDetails/upcomingProjectModal/UpcomingProjectsModal";

type Props = {};

const ProjectList = (props: Props) => {
  const { user } = useContext(AuthContext);
  const { builderDetails } = useContext(BuilderContext);
  const { setDashboardHeader } = useContext(UIContext);
  const [allPrrojectPagination, setAllPrrojectPagination] =
    useState<IPagination>({
      currentPage: 1,
      pageCount: 1,
    });
  const [pendingProjectagination, setPendingProjectPagination] =
    useState<IPagination>({
      currentPage: 1,
      pageCount: 1,
    });
  const [allProjectList, setAllProjectList] = useState<IProject[]>([]);
  const [pendingProjectList, setPendingProjectList] = useState<IProject[]>([]);
  const [value, setValue] = useState<number>(0);

  const [upcomingProjectList, setUpcomingProjectList] = useState([]);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleAllProjectPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setAllPrrojectPagination(prev => ({
      ...prev,
      currentPage: value,
    }));
  };

  const handlePendingProjectPageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPendingProjectPagination(prev => ({
      ...prev,
      currentPage: value,
    }));
  };

  const getAllUpcomingProjects = useCallback(async () => {
    try {
      const response = await api.upcomingProjects.getUpcomingProjects({});
      console.log(response);
      if (response) {
        console.log("gg", response);
        setUpcomingProjectList(response);
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  }, []);

  const getAllProjects = useCallback(async () => {
    try {
      let response: any = {};
      const filter = {
        page: allPrrojectPagination.currentPage,
      };
      response = await api.project.getPlots(filter);
      // if (user?.role === ROLES.super_admin) {
      //   const filter = {
      //     page: allPrrojectPagination.currentPage,
      //   };
      //   response = await api.project.getPlots(filter);
      // } else {
      //   const filter = {
      //     page: allPrrojectPagination.currentPage,
      //     role: user?.role,
      //     search_id: builderDetails ? builderDetails._id : user?._id,
      //   };
      //   response = await api.project.getAdminPlots(filter);
      // }
      if (response) {
        setAllProjectList(response.result);
        setAllPrrojectPagination(response.pagination);
        console.log("response", response);
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  }, [allPrrojectPagination.currentPage]);

  const getPendigProjects = useCallback(async () => {
    try {
      const filter = {
        page: pendingProjectagination.currentPage,
        status: PROJECT_STATUS.pending,
      };
      const response = await api.project.getPlots(filter);
      if (response) {
        setPendingProjectList(response.result);
        setPendingProjectPagination(response.pagination);
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  }, [pendingProjectagination.currentPage]);

  //for full data
  const downloadAllProjectsFullData = useCallback(async () => {
    try {
      const filter = {
        page: 0,
      };
      const response = await api.project.getPlots(filter);
      if (response) {
        downloadExcel(response.result, "project");
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  }, []);

  const downloadPendigProjectsFullData = useCallback(async () => {
    try {
      const filter = {
        page: 0,
        status: PROJECT_STATUS.pending,
      };
      const response = await api.project.getPlots(filter);
      if (response) {
        downloadExcel(response.result, "project");
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  }, []);

  const navigate = useNavigate();
  const handleNavigateToAddPlots = () => {
    navigate("/add-plots");
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  useEffect(() => {
    getAllProjects();
    getPendigProjects();
  }, [getAllProjects, getPendigProjects]);

  useEffect(() => {
    getAllUpcomingProjects();
  }, [getAllUpcomingProjects]);

  useEffect(() => {
    setDashboardHeader("All Projects");
  }, [setDashboardHeader]);

  //page
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: {
                border: "none",
                backgroundColor: "#558b2f",
              },
            }}
            className="glb-tab-panel"
          >
            <Tab label="All" {...a11yProps(0)} />
            <Tab label="Upcoming Projects" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "right",
              marginBottom: "20px",
              gap: "5px",
            }}
          >
            <Download onClick={downloadAllProjectsFullData} />
            <Button
              className="btn"
              variant="contained"
              onClick={handleNavigateToAddPlots}
            >
              Add Plots
            </Button>
          </div>
          <DataGrid
            colDefs={PlotsDefs}
            rowData={allProjectList}
            key={2}
          ></DataGrid>
          <BasicPagination
            currentPage={allPrrojectPagination.currentPage}
            handlePageChange={handleAllProjectPageChange}
            pageCount={allPrrojectPagination.pageCount}
          />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              alignItems: "right",
              marginBottom: "20px",
              gap: "5px",
            }}
          >
            {/* <Download onClick={downloadPendigProjectsFullData} /> */}
            <Button
              className="btn"
              variant="contained"
              onClick={handleOpenModal}
            >
              Add Upcoming Projects
            </Button>
          </div>
          <DataGrid
            colDefs={UpcomingProjectDefs}
            rowData={upcomingProjectList}
            key={0}
          ></DataGrid>
          {/* <BasicPagination
            currentPage={pendingProjectagination.currentPage}
            handlePageChange={handlePendingProjectPageChange}
            pageCount={pendingProjectagination.pageCount}
            key={0}
          /> */}
        </TabPanel>
      </Box>
      <UpcomingProjectModal open={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default ProjectList;
