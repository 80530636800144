import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { numberToWords } from "../../../../utils/commonFunction/numberToWords";

const ProjectFormTextField = ({
  projectDetails,
  label,
  name,
  width,
  type,
  className,
  unitOptions,
  unit,
  handleChange,
  required = false,
}: any) => {
  const [inputValue, setInputValue] = useState<number | string>(
    projectDetails[name] || ""
  ); // To store the input price
  const [isActive, setIsActive] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState<string>("SQFT"); // Default unit is "SQ Ft"
  const [numberInWords, setNumberInWords] = useState<string>("");

  const handleFocus = () => {
    setIsActive(true);
  };
  const handleBlur = () => {
    setIsActive(false);
  };
  const handlePriceUnitChange = (event: any) => {
    setSelectedUnit(event.target.value as string);
  };

  const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue); // Update local price value state
    // handleNumberToText(newValue);
  };

  const handleNumberToText = (newValue: string) => {
    const convertedNumberValue = numberToWords(parseFloat(newValue as string));
    return convertedNumberValue;
    // setNumberInWords(convertedNumberValue);
  };

  // Whenever the price or unit changes, calculate the value in basic form (like in rupees)
  useEffect(() => {
    let valueToSend = inputValue;
    // if (inputValue && type === "number") {
    //   const newValue = parseFloat(inputValue as string);
    //   // const priceInSqFt = convertToSqFt(
    //   //   parseFloat(inputValue as string),
    //   //   selectedUnit
    //   // );
    //   const convertedNumberValue = numberToWords(newValue);
    //   setNumberInWords(convertedNumberValue);
    //   // console.log("Value in Words ->", convertedNumberValue);
    //   valueToSend = newValue;
    // } else {
    //   setNumberInWords("");
    // }
    const customEvent = {
      target: {
        name: name,
        value: valueToSend,
      },
    };
    handleChange(customEvent as React.ChangeEvent<HTMLInputElement>);
  }, [inputValue, selectedUnit, numberInWords]);

  const handleDateChange = (date: Dayjs | null) => {
    const event = {
      target: {
        name: name,
        value: date ? date.format("YYYY-MM-DD") : "",
      },
    } as React.ChangeEvent<HTMLInputElement>;

    handleChange(event);
  };

  return (
    <div className={className}>
      <label style={{ fontSize: "16px" }}>
        {label}
        {required ? (
          <span style={{ color: "red", fontSize: 20, fontWeight: "bold" }}>
            *
          </span>
        ) : null}
      </label>
      {name === "construction_date" || name === "launching_date" ? (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={label}
            value={projectDetails[name] ? dayjs(projectDetails[name]) : null}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
      ) : name === "price_per_sq" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width,
          }}
        >
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <TextField
              type="number"
              variant="outlined"
              sx={{ width: "100%", marginRight: "8px" }}
              value={inputValue}
              onChange={handleNumberChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              name={name}
              required={required}
            />
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel>Unit</InputLabel>
              <Select
                value={selectedUnit}
                onChange={handlePriceUnitChange}
                displayEmpty
                variant="outlined"
              >
                {unitOptions.map((option: string) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {inputValue && isActive && (
            <p
              style={{
                marginTop: "8px",
                color: "orange",
                fontSize: 15,
                whiteSpace: "normal",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
              }}
            >
              {handleNumberToText(inputValue as string)}
            </p>
          )}
        </div>
      ) : type === "number" ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width,
          }}
        >
          <TextField
            id="outlined-basic"
            type="number"
            variant="outlined"
            sx={{ width: "100%" }}
            value={inputValue}
            onChange={handleNumberChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            name={name}
            required={required}
          />
          {inputValue && isActive && (
            <p
              style={{
                marginTop: "8px",
                color: "orange",
                fontSize: 15,
                wordBreak: "break-word",
                whiteSpace: "normal",
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
              }}
            >
              {handleNumberToText(inputValue as string)}
            </p>
          )}
        </div>
      ) : (
        <TextField
          id="outlined-basic"
          type={type}
          variant="outlined"
          sx={{ width: width }}
          value={projectDetails[name]}
          onChange={handleChange}
          name={name}
          required={required}
        />
      )}
    </div>
  );
};

export default ProjectFormTextField;
