import { Button } from "@mui/material";
import { AdColDefs } from "../../../../constants/advertisement/adColDefs";
import { useNavigate } from "react-router-dom";
import DataGrid from "../../../shared/dataGrid/DataGrid";
import { useEffect, useContext } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import AddToPhotosOutlinedIcon from "@mui/icons-material/AddToPhotosOutlined";
import BasicPagination from "../../../shared/basicPagination/BasicPagination";
import Download from "../../../shared/downloadbtn/Download";

const Advertisement = () => {
  const navigate = useNavigate();
  const { setDashboardHeader } = useContext(UIContext);
  useEffect(() => {
    setDashboardHeader("Advertisements");
  }, [setDashboardHeader]);
  return (
    <div>
      <div className="add-btn">
        <Download />
        <Button
          variant="contained"
          className="btn"
          endIcon={<AddToPhotosOutlinedIcon />}
          onClick={() => navigate("/add-banner")}
        >
          Add Banner
        </Button>
      </div>
      <DataGrid colDefs={AdColDefs} rowData={[]} key={0} />
      {/* <BasicPagination /> */}
    </div>
  );
};

export default Advertisement;
