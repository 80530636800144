export const LANDMARK_TYPE_OPTIONS: { label: string; value: string }[] = [
  {
    label: "HOSPITAL",
    value: "Hospital",
  },
  {
    label: "SCHOOL",
    value: "School",
  },
  {
    label: "COLLEGE",
    value: "College",
  },
  {
    label: "BUS STAND",
    value: "Bus Stand",
  },
  {
    label: "AIRPORT",
    value: "Airport",
  },
  {
    label: "RAILWAY STATION",
    value: "Railway Station",
  },
  {
    label: "POLICE STATION",
    value: "Police Station",
  },
  {
    label: "FIRE STATION",
    value: "Fire Station",
  },
  {
    label: "MALL",
    value: "Mall",
  },
  {
    label: "RESTAURANT",
    value: "Restaurant",
  },
  {
    label: "HOTEL",
    value: "Hotel",
  },
  {
    label: "ATM",
    value: "ATM",
  },
  {
    label: "BANK",
    value: "Bank",
  },
  {
    label: "PETROL PUMP",
    value: "Petrol Pump",
  },
  {
    label: "GAS STATION",
    value: "Gas Station",
  },
  {
    label: "POST OFFICE",
    value: "Post Office",
  },
  {
    label: "SHOPPING MALL",
    value: "Shopping Mall",
  },
  {
    label: "GROCERY STORE",
    value: "Grocery Store",
  },
  {
    label: "VEGETABLE MARKET",
    value: "Vegetable Market",
  },
  {
    label: "FRUIT MARKET",
    value: "Fruit Market",
  },
  {
    label: "CINEMA HALL",
    value: "Cinema Hall",
  },
  {
    label: "PARK",
    value: "Park",
  },
  {
    label: "GARDEN",
    value: "Garden",
  },
  {
    label: "ZOO",
    value: "Zoo",
  },
  {
    label: "MUSEUM",
    value: "Museum",
  },
  {
    label: "LIBRARY",
    value: "Library",
  },
  {
    label: "STADIUM",
    value: "Stadium",
  },
  {
    label: "PLAYGROUND",
    value: "Playground",
  },
  {
    label: "GYM",
    value: "Gym",
  },
  {
    label: "YOGA CENTER",
    value: "Yoga Center",
  },
  {
    label: "TEMPLE",
    value: "Temple",
  },
  {
    label: "MOSQUE",
    value: "Mosque",
  },
  {
    label: "CHURCH",
    value: "Church",
  },
  {
    label: "GURUDWARA",
    value: "Gurudwara",
  },
  {
    label: "OTHER",
    value: "Other",
  },
];

export const VEHICLE_OPTION: {
  label: string;
  value: string;
}[] = [
  { label: "WALK", value: "walk" },
  { label: "CYCLE", value: "cycle" },
  { label: "CAR", value: "car" },
  { label: "BIKE", value: "bike" },
  { label: "TRAIN", value: "train" },
];
