import { useState } from "react";
import { UNIT_LIST } from "../../../../constants/units/Units";


const ProjectFormWithUnit = ({lebel,convertedValue,setConvertedValue ,name}:any) => {
    const conversionRates:any = {
        SQFT: 1,
        ACRE: 43560,
        GUNTHA: 1089,
        CENT: 435.6,
      };
      
      const convertToSqFt = (value:any, unit:any) => {
        return value * conversionRates[unit];
      };
      
        const [value, setValue] = useState('');
        const [unit, setUnit] = useState('SQFT');
        // const [convertedValue, setConvertedValue] = useState<any>('');
      
        const handleValueChange = (e:any) => {
          setValue(e.target.value);
          setConvertedValue(convertToSqFt(e.target.value, unit));
        };
      
        const handleUnitChange = (e:any) => {
          setUnit(e.target.value);
          setConvertedValue(convertToSqFt(value, e.target.value));
        };
  return (
    <div className="form-group" style={{display:"flex"}}>
        <div>{lebel}</div>
      {/* <label htmlFor="input-with-unit"></label> */}
      <div className="input-with-unit">
        <input type="number"
          id="input-with-unit"
          className="number-input"
          placeholder="Enter number"
          name={name}
          value={value}
          onChange={handleValueChange} />
        <select className="unit-select" value={unit} onChange={handleUnitChange}>
        {UNIT_LIST.map((unit) => (
            <option key={unit.value} value={unit.value}>
              {unit.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default ProjectFormWithUnit
