import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import ProjectFormTextField from "../../../../../shared/formTextField/projectFormTextField/ProjectFormTextField";
import { IBankDetailsAccodianInterface } from "../../../../../../@types/interface/bankDetailsAccodian.interface";
import {
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { BankPartnersOptions } from "../../../../../../constants/addPlotsFormFields/BankPartnersOptions";
import ProjectDownPaymentFormTextField from "../../../../../shared/formTextField/bankDetails/DropdownForm";

const BankDetailsAccordion: React.FC<IBankDetailsAccodianInterface> = ({
  projectDetails,
  handleChange,
  setProjectDetails,
}) => {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  // const [loanUnit, setLoanUnit] = useState("%");
  const [downpaymentUnit, setDownpaymentUnit] = useState("%");
  // Helper function to create a synthetic event
  const createSyntheticEvent = (name: string, value: boolean) => ({
    target: { name, value },
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    handleChange(
      createSyntheticEvent(
        name,
        checked
      ) as unknown as React.ChangeEvent<HTMLInputElement>
    );
  };

  const handleDownpaymentUnitChange = (event: SelectChangeEvent<string>) => {
    const customEvent = {
      target: {
        name: "downpayment_unit",
        value: event.target.value,
      },
    };
    handleChange(customEvent as React.ChangeEvent<HTMLInputElement>);
    setDownpaymentUnit(event.target.value);
  };

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setProjectDetails((prevDetails) => ({
      ...prevDetails,
      [name]: parseFloat(value),
    }));
  };

  const handleBankPartnersChange = (event: { target: { value: any } }) => {
    const { value } = event.target;
    setProjectDetails({ ...projectDetails, bank_partners: value });
  };

  return (
    <Accordion defaultExpanded style={{ display: "block", marginTop: "20px" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2-content"
        id="panel2-header"
      >
        <h3>Bank Details</h3>
      </AccordionSummary>
      <AccordionDetails>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
          }}
        >
          <div className="checkbox-flex">
            <label className="bank-checkbox-labels">Bank Loan Available:</label>
            <Checkbox
              {...label}
              color="success"
              name="has_bank_partner"
              checked={projectDetails.has_bank_partner || false}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="checkbox-flex">
            <label className="bank-checkbox-labels">Has Bank Partner:</label>
            <Checkbox
              {...label}
              color="success"
              name="is_bank_loan_available"
              checked={projectDetails.is_bank_loan_available || false}
              onChange={handleCheckboxChange}
            />
          </div>
          <div className="checkbox-flex">
            <label className="bank-checkbox-labels">EMI option:</label>
            <Checkbox
              {...label}
              color="success"
              name="is_emi_option"
              checked={projectDetails.is_emi_option || false}
              onChange={handleCheckboxChange}
            />
          </div>
          {projectDetails.is_emi_option && (
            <ProjectFormTextField
              className={"label-input-flex"}
              required={true}
              width={"60%"}
              projectDetails={projectDetails}
              name={"emi_months"}
              label={"EMI Duration (months):"}
              type={"number"}
              handleChange={handleChange}
            />
          )}
        </div>
        {/* <div className="project-flex-inputs"> */}
        {/* </div> */}
        <div className="project-flex-inputs">
          <ProjectFormTextField
            className={"label-input-flex"}
            required={true}
            width={"60%"}
            projectDetails={projectDetails}
            name={"min_price"}
            label={"Minimum Price:"}
            type={"number"}
            handleChange={handleChange}
          />

          <ProjectFormTextField
            className={"label-input-flex"}
            width={"60%"}
            projectDetails={projectDetails}
            name={"max_price"}
            required={true}
            label={"Maximum Price:"}
            type={"number"}
            handleChange={handleChange}
          />
          <ProjectFormTextField
            className={"label-input-flex"}
            width={"60%"}
            projectDetails={projectDetails}
            name={"emi_starts"}
            label={"Emi Starts From:"}
            type={"number"}
            handleChange={handleChange}
          />

          <ProjectFormTextField
            className={"label-input-flex"}
            width={"60%"}
            projectDetails={projectDetails}
            name={"loan_upto"}
            label={"Loan Upto:"}
            type={"number"}
            handleChange={handleChange}
          />

          <ProjectDownPaymentFormTextField
            className={"label-input-flex"}
            width={"60%"}
            projectDetails={projectDetails}
            name={"downpayment"}
            label={"Downpayment:"}
            type={"number"}
            unitOptions={["%", "Lakh"]}
            unit={downpaymentUnit}
            handleUnitChange={handleDownpaymentUnitChange}
            handleChange={handleInputChange}
          />

          <div className="bank-partners-inputs">
            <label
              style={{ fontSize: "16px", marginLeft: "20px" }}
              className="ml-11"
            >
              Bank Partners:{" "}
            </label>
            <Select
              multiple
              value={projectDetails.bank_partners}
              onChange={handleBankPartnersChange}
              displayEmpty
              style={{ width: "55%", margin: "10px 0" }}
              renderValue={(selected) => {
                if ((selected as string[]).length === 0) {
                  return "Select Bank Partners";
                }
                return (selected as string[]).join(", ");
              }}
            >
              <MenuItem value="" disabled>
                Select Bank Partners
              </MenuItem>
              {BankPartnersOptions.map((bankPartner) => (
                <MenuItem key={bankPartner} value={bankPartner}>
                  <Checkbox
                    checked={
                      projectDetails.bank_partners
                        ? projectDetails.bank_partners.indexOf(bankPartner) > -1
                        : false
                    }
                  />
                  <ListItemText primary={bankPartner} />
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default BankDetailsAccordion;
