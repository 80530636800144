import { loginAdmin } from "./auth/loginAdmin";
import { getOtp } from "./auth/otpAdmin";
import { addBanner } from "./banner/postBanner";
import {
  forwardEnqury,
  getBooking,
  getForwardEnquryList,
  getProjectEnquiry,
  updateEnquiryStatus,
} from "./booking/booking";
import { getBuilder } from "./builders/getBuilder";
import { getBuilderByID } from "./builders/getBuilderByID";
import { addBuilder } from "./builders/postBuilder";
import { getCustomer } from "./customer/getCustomer";
import { getEmployeeList } from "./employee/getEmployee";
import { getUsers } from "./user/getUser";
import { createAdmin } from "./permission/userManagement/createAdmin";
import { getBuilderList } from "./permission/userManagement/getBuilderList";
import {
  addPlot,
  uploadLayoutImageMedia,
  uploadProjectMedia,
} from "./projects/addProject";
import { adminAssignProject } from "./projects/assignProject";
import {
  getAdminPlots,
  getAssignedProjectList,
  getProjectDetails,
  getSelectiveProjectDetails,
} from "./projects/getAdminProjects";
import { getAllProjects, getPhaseList, getPlots } from "./projects/getProjects";
import { updatePlotPostion } from "./projects/updatePlotPosition";
import { getProjectList } from "./projects/getProjectList";
import { addProjectFacility } from "./projects/addProjectFacility";
import { getProjectFacilities } from "./projects/getProjectFacilities";
import { deleteProjectFacility } from "./projects/deleteProjectFacility";
import { updateProject } from "./projects/updateProject";
import {
  getBookingDetails,
  getEnquiryDetails,
} from "./projects/getEnquiryDetails";
import { createFollowUp } from "./followup/createFollowup";
import { getFollowUp } from "./followup/getFollowUp";
import { getResellerEnquiry } from "./resellerEnquiry/getResellerEnquiry";
import { getAdminAndBuilder } from "./projects/getAdminAndProjects";
import { addBank } from "./bank/postBank";
import { getBankContacts } from "./bank/getBankContacts";
import { getUpcomingProjects } from "./upcomingProjects/getUpcomingProjects";
import { addUpcomingProject } from "./upcomingProjects/addUpcomingProject";
import { addOffer } from "./offers/offer";
import { getAdmin } from "./admin/admin";

export const api = {
  customer: {
    getCustomer,
  },
  builder: {
    getBuilder,
    addBuilder,
    getBuilderList,
    getBuilderByID,
  },
  admin: {
    createAdmin,
    adminAssignProject,
    getEmployeeList,
    getUsers,
    getAdmin,
  },
  auth: {
    loginAdmin,
    getOtp,
  },
  permission: {
    getBuilderList: getBuilderList,
  },
  followUp: {
    createFollowUp,
    getFollowUp,
  },
  offers: {
    addOffer,
  },
  project: {
    addPlot,
    uploadProjectMedia,
    uploadLayoutImageMedia,
    getPlots,
    getPhaseList,
    getAdminPlots,
    getProjectDetails,
    getSelectiveProjectDetails,
    getAllProjects,
    getAssignedProjectList,
    updatePlotPostion,
    getProjectList,
    addProjectFacility,
    getProjectFacilities,
    deleteProjectFacility,
    updateProject,
    getAdminAndBuilder,
  },
  booking: {
    getBooking,
    forwardEnqury,
    getProjectEnquiry,
    getForwardEnquryList,
    updateEnquiryStatus,
    getBookingDetails,
    getEnquiryDetails,
  },
  banner: {
    createBanner: addBanner,
  },
  bank: {
    addBank,
    getBankContacts,
  },
  resellerEnquiry: {
    getResellerEnquiry,
  },
  upcomingProjects: {
    getUpcomingProjects,
    addUpcomingProject,
  },
};
