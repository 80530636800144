import { useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import SwitchModel from "../modal/SwitchModel";

interface SwitchModalFormProps {
  open: boolean;
  onConfirm: (formData: any) => void;
  onClose: () => void;
  rowData: any;
}

const SwitchModalForm: React.FC<SwitchModalFormProps> = ({
  open,
  onConfirm,
  onClose,
  rowData,
}) => {
  const [formData, setFormData] = useState<any>({
    customer_name: rowData?.customer?.full_name || "",
    customer_phone: "",
    customer_budget: "",
    desired_facing: "",
    notes: "",
  });
  const [errors, setErrors] = useState<any>({});

  const validateForm = () => {
    let newErrors: any = {};

    if (!formData.customer_phone) {
      newErrors.customer_phone = "Phone number is required";
    } else if (!/^\d{10}$/.test(formData.customer_phone)) {
      newErrors.customer_phone = "Phone number must be 10 digits";
    }

    if (!formData.customer_budget) {
      newErrors.customer_budget = "Budget is required";
    } else if (!/^(\d+(\.\d+)?K?)$/i.test(formData.customer_budget)) {
      newErrors.customer_budget =
        "Budget must be a number (optionally followed by 'K')";
    }
    if (!formData.desired_facing) {
      newErrors.desired_facing = "Desired facing is required";
    }

    setErrors(newErrors);

    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleBudgetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace("K", "");
    setFormData({ ...formData, customer_budget: `${value}K` });
  };

  const handleConfirm = () => {
    if (validateForm()) {
      onConfirm(formData);
    }
  };

  return (
    <SwitchModel
      open={open}
      onConfirm={handleConfirm}
      onClose={onClose}
      title="Wanna Share the Information?"
    >
      <TextField
        id="customer-name"
        label="Customer Name"
        variant="standard"
        value={formData.customer_name}
        InputProps={{
          readOnly: true,
        }}
        sx={{ mt: 2 }}
      />
      <TextField
        id="phone-number"
        label="Phone Number"
        variant="standard"
        name="customer_phone"
        value={formData.customer_phone}
        onChange={handleInputChange}
        error={!!errors.customer_phone}
        helperText={errors.customer_phone}
        sx={{ ml: 15, mt: 2 }}
      />
      <TextField
        id="budget"
        label="Budget"
        variant="standard"
        name="customer_budget"
        value={formData.customer_budget}
        onChange={handleBudgetChange}
        error={!!errors.customer_budget}
        helperText={errors.customer_budget}
        sx={{ mt: 3 }}
      />
      <FormControl
        sx={{ mt: 4, ml: 15, width: 190 }}
        error={!!errors.desired_facing}
      >
        <InputLabel id="desired-facing-label">Desired Facing</InputLabel>
        <Select
          labelId="desired-facing-label"
          id="desired-facing"
          value={formData.desired_facing}
          label="Desired Facing"
          name="desired_facing"
          onChange={handleChangeSelect}
        >
          <MenuItem value={"north"}>North</MenuItem>
          <MenuItem value={"west"}>West</MenuItem>
          <MenuItem value={"south"}>South</MenuItem>
          <MenuItem value={"east"}>East</MenuItem>
        </Select>
        {errors.desired_facing && (
          <p
            style={{
              color: "red",
              fontSize: "0.75rem",
              marginTop: "0.25rem",
            }}
          >
            {errors.desired_facing}
          </p>
        )}
      </FormControl>
      <TextField
        id="post-content"
        label="Add Notes...."
        variant="standard"
        multiline
        rows={2}
        fullWidth
        name="notes"
        value={formData.notes}
        onChange={handleInputChange}
        sx={{ mt: 2 }}
      />
    </SwitchModel>
  );
};

export default SwitchModalForm;
