import React, { useCallback, useContext, useEffect, useState } from "react";
import Download from "../../shared/downloadbtn/Download";
import { downloadExcel } from "../../../utils/commonFunction/downloadExcel";
import DataGrid from "../../shared/dataGrid/DataGrid";
import BasicPagination from "../../shared/basicPagination/BasicPagination";
import { ResellerEnquiryColDefs } from "../../../constants/resellerEnquiry/ResellerEnquiry";
import UIContext from "../../../contexts/uiContext/UIContext";
import { api } from "../../../utils/api";

const ITEMS_PER_PAGE = 5;

const ResellerEnquiry = () => {
  const { setDashboardHeader } = useContext(UIContext);
  const [rowData, setRowData] = useState([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const fetchEnquiries = useCallback(
    async (filterQuery: any) => {
      try {
        const filter = {
          ...filterQuery,
          page: currentPage,
        };
        const response = await api.resellerEnquiry.getResellerEnquiry(filter);
        if (response) {
          setRowData(response.result);
          const totalItems = response.pagination.total;
          const calculatedTotalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);
          setTotalPages(calculatedTotalPages);
          setCurrentPage(response.pagination.currentPage);
          console.log("response", response.pagination);
        }
      } catch (error) {
        console.error("Error while fetching data:", error);
      }
    },
    [currentPage]
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    fetchEnquiries({});
  }, [fetchEnquiries]);

  useEffect(() => {
    setDashboardHeader("All Reseller Enquiries");
  }, [setDashboardHeader]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "right",
          marginBottom: "20px",
        }}
      >
        <Download
        // onClick={onDownloadClick}
        />
      </div>
      <DataGrid colDefs={ResellerEnquiryColDefs} rowData={rowData} key={0} />
      <BasicPagination
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        pageCount={totalPages}
      />
    </div>
  );
};

export default ResellerEnquiry;
