import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { REPORT_TYPES } from '../../../../constants/reportTypes/ReportTypes'

const ReportCellRenderer = ({data}: any) => {
    console.log(data.report_type)

    const navigate = useNavigate()

    const handleRouteToReportDetails = () =>{
        if(data.report_type === REPORT_TYPES.project_report)
        navigate('/reports/project-reports')
        if(data.report_type === REPORT_TYPES.city_report)
            navigate('/reports/city-reports')
        if(data.report_type === REPORT_TYPES.builder_report)
            navigate('/reports/builder-reports')
    }

  return (
    <Button variant="outlined" className='green-outlined-button' onClick={handleRouteToReportDetails}>Generate Report</Button>
  )
}

export default ReportCellRenderer