import React from "react";
import TextField from "@mui/material/TextField";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { IFormTextFieldProps } from "../../../../@types/props/formTextField.props";

const ProjectDownPaymentFormTextField: React.FC<IFormTextFieldProps> = ({
  projectDetails,
  label,
  name,
  width,
  type,
  className,
  handleChange,
  unitOptions,
  unit,
  handleUnitChange,
}) => {
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Combine the value with the selected unit
    const valueWithUnit = `${event.target.value}${unit}`;
    // Create a custom event with the combined value
    const customEvent = {
      ...event,
      target: {
        ...event.target,
        name,
        value: valueWithUnit,
      },
    };
    handleChange(customEvent as React.ChangeEvent<HTMLInputElement>);
  };

  const getValueWithoutUnit = (
    value: string | number | boolean | string[] | undefined
  ): string => {
    if (typeof value === "string") {
      return value.replace(/[%Lakh]+$/, "");
    }
    if (typeof value === "number" || typeof value === "boolean") {
      return value.toString();
    }
    return "";
  };

  return (
    <div
      className={className}
      style={{ display: "flex", alignItems: "center" }}
    >
      {projectDetails[name] ? (
        <TextField
          label={label}
          name={name}
          value={getValueWithoutUnit(projectDetails[name] || "0") || ""}
          onChange={handleValueChange}
          type={type}
          style={{ width: width }}
          variant="outlined"
        />
      ) : null}
      {unitOptions && (
        <Select
          value={unit}
          onChange={handleUnitChange}
          style={{ marginLeft: "10px" }}
        >
          {unitOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      )}
    </div>
  );
};

export default ProjectDownPaymentFormTextField;
