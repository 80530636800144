import React, { ReactNode, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { PlotDetailsFormProps } from "../../../../../../@types/props/plotdDetailsForm.props";
// import { ROLES } from "../../../../../../constants/roles/Roles";
import { ILayout } from "../../../../../../@types/interface/layout.interface";
import { LANDTYPES_TYPE_OPTIONS } from "../../../../../../constants/landTypes/landTypes";
import { UNIT_LIST } from "../../../../../../constants/units/Units";

const PlotDetailsForm: React.FC<PlotDetailsFormProps> = ({
  phasingOptions,
  ownerOptions,
  adminOptions,
  index,
  builderOptions,
  blockPhaseOptions,
  handleSavePlotDetails,
  selectedPoint,
  handleCloseToolkit,
  removeUnwantedPlot,
  mode,
}) => {
  const [plotDetails, setPlotDetails] = useState<ILayout>(selectedPoint);
  const [plotSize, setPlotSize] = useState<number>(plotDetails.plot_size);
  const [unitAmount, setUnitAmount] = useState<number>(1);

  // console.log("===>selectedPoint", selectedPoint);
  // console.log("===>plot details", plotDetails);
  // console.log("BlockPhaseOptions===>", blockPhaseOptions);
  // console.log(`Builder Options==> ${builderOptions}`);

  const handlePlotSizeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(event.target.value);
    setPlotSize(value);
    if (unitAmount) {
      setPlotDetails({
        ...plotDetails,
        plot_size: value * unitAmount,
      });
    }
  };
  const handleUnitChange = (event: SelectChangeEvent<unknown>) => {
    const amount = event.target.value as number;
    setUnitAmount(amount);
    if (plotSize) {
      setPlotDetails({
        ...plotDetails,
        plot_size: plotSize * amount,
      });
    }
  };
  const handleChangePlotDetails = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<unknown>,
    child?: ReactNode
  ) => {
    const { name, value } = event.target;
    setPlotDetails(Object.assign({}, plotDetails, { [name]: value }));
  };

  // autoCompleteHandler
  const handleAutoCompleteChanges = (
    field: "phase_number" | "block_number" | "assigned_to",
    event: React.SyntheticEvent<unknown>,
    newValue: string | null
  ) => {
    console.log(`field==>${field} value==>${newValue}`);

    setPlotDetails(Object.assign({}, plotDetails, { [field]: newValue || "" }));
  };

  const createSyntheticEvent = (name: string, value: boolean) => ({
    target: { name, value },
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    console.log("===>name", name, checked);
    handleChangePlotDetails(
      createSyntheticEvent(
        name,
        checked
      ) as unknown as React.ChangeEvent<HTMLInputElement>
    );
  };

  const handleAssignedtoChange = (
    event: SelectChangeEvent<string>,
    child: ReactNode
  ) => {
    const { name, value } = event.target;
    setPlotDetails({
      ...plotDetails,
      [name]: value,
    });
  };

  const handleOwnerTypeChange = (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    const { value } = event.target;
    setPlotDetails({
      ...plotDetails,
      assigned_user_role: value,
      assigned_to: "",
    });
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("====>plotDetails", plotDetails);
    handleSavePlotDetails(plotDetails, index);
  };

  return (
    <div
      style={{
        width: "400px",
        paddingTop: 5,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
      }}
      className="flex gap-4 flex-col justify-center items-start rounded-xl px-10 py-3"
    >
      <h4 className="">Fill the Details Below</h4>

      {mode === "DEFAULT" ? (
        <>
          <div className="w-full flex flex-col justify-center text-black items-start px-10">
            <TextField
              id="plot_number"
              type="number"
              label="Plot Number"
              value={plotDetails.plot_number ?? ""}
              name="plot_number"
              variant="standard"
              onChange={handleChangePlotDetails}
              style={{ marginRight: "25px" }}
              required
              fullWidth
            />
            <div
              style={{ marginBottom: 2, marginTop: 11 }}
              className="flex-space-between"
            >
              {/* <TextField
                id="phase_number"
                name="phase_number"
                type="text"
                value={plotDetails.phase_number ?? ""}
                label="Phase Number"
                variant="standard"
                onChange={handleChangePlotDetails}
                style={{ marginRight: "25px" }}
                required
              /> */}
              <Autocomplete
                id="phase_number_autocomplete"
                freeSolo
                options={blockPhaseOptions.phase_number}
                value={plotDetails.phase_number ?? ""}
                onChange={(event, newValue) =>
                  handleAutoCompleteChanges("phase_number", event, newValue)
                }
                onInputChange={(event, newInputValue) => {
                  setPlotDetails({
                    ...plotDetails,
                    phase_number: newInputValue,
                  });
                }}
                sx={{
                  width: "45%",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="phase_number"
                    name="phase_number"
                    type="text"
                    label="Phase Number"
                    variant="standard"
                    style={{ marginRight: "25px" }}
                    required
                  />
                )}
              />
              {/* <TextField
                id="block_number"
                type="text"
                label="Block Number"
                value={plotDetails.block_number ?? ""}
                name="block_number"
                variant="standard"
                onChange={handleChangePlotDetails}
                required
              /> */}
              <Autocomplete
                id="block_number_autocomplete"
                freeSolo
                options={blockPhaseOptions.block_number}
                value={plotDetails.block_number ?? ""}
                onChange={(event, newValue) =>
                  handleAutoCompleteChanges("block_number", event, newValue)
                }
                onInputChange={(event, newInputValue) => {
                  setPlotDetails({
                    ...plotDetails,
                    block_number: newInputValue,
                  });
                }}
                sx={{
                  width: "45%",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="block_number"
                    type="text"
                    label="Block Number"
                    name="block_number"
                    variant="standard"
                    required
                  />
                )}
              />
            </div>
            <div style={{ display: "flex", marginBottom: 2, marginTop: 11 }}>
              <TextField
                id="plot_size"
                type="number"
                label="Total Area"
                value={plotSize ?? ""}
                name="plot_size"
                variant="standard"
                onChange={handlePlotSizeChange}
                style={{ marginRight: "25px", width: "70%" }}
                required
              />

              <FormControl variant="standard" style={{ width: "25%" }}>
                <InputLabel id="unit-select-label">Unit</InputLabel>
                <Select
                  labelId="unit-select-label"
                  id="unitSelect"
                  value={unitAmount}
                  name="unit"
                  onChange={handleUnitChange}
                  label="Unit"
                  required
                >
                  {UNIT_LIST.map((unit) => (
                    <MenuItem key={unit.value} value={unit.amount}>
                      {unit.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <TextField
                id="price"
                name="price"
                type="number"
                label="Price"
                variant="standard"
                value={plotDetails.price}
                onChange={handleChangePlotDetails}
              /> */}
            </div>
            <FormControl variant="standard" fullWidth sx={{ mt: 2 }}>
              <InputLabel id="land_type">Land Type</InputLabel>
              <Select
                labelId="land_type"
                variant="standard"
                name="land_type"
                value={plotDetails.land_type ?? ""}
                onChange={handleAssignedtoChange}
                required
                fullWidth
              >
                {LANDTYPES_TYPE_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box
              sx={{
                minWidth: 120,
                marginTop: 3,
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 2,
              }}
            >
              <FormControl variant="standard" style={{ width: 120 }} required>
                <InputLabel id="facing-select-label">Facing</InputLabel>
                <Select
                  labelId="facing-select-label"
                  id="facing"
                  name="facing"
                  value={plotDetails.facing ?? ""}
                  onChange={handleChangePlotDetails}
                  label="Facing"
                >
                  {phasingOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                id="dimension"
                name="dimension"
                type="string"
                label="Dimension"
                variant="standard"
                value={plotDetails.dimension ?? ""}
                onChange={handleChangePlotDetails}
                required
              />
            </Box>
          </div>
        </>
      ) : (
        <>
          <div className="w-full flex flex-col justify-center text-black items-start px-10">
            <Box>
              <TextField
                id="owner-type"
                select
                label="Owner Type"
                value={plotDetails.assigned_user_role ?? ""}
                name="assigned_user_role"
                variant="standard"
                style={{ width: 180 }}
                onChange={handleOwnerTypeChange}
              >
                {ownerOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            {plotDetails.assigned_user_role !== "" && (
              <Box sx={{ minWidth: 120, marginTop: 3, marginBottom: 2 }}>
                <Autocomplete
                  id="builder-name-autocomplete"
                  options={
                    plotDetails.assigned_user_role === "BUILDER"
                      ? builderOptions
                      : adminOptions
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    handleAutoCompleteChanges(
                      "assigned_to",
                      event,
                      newValue ? newValue.value : null
                    );
                  }}
                  value={
                    plotDetails.assigned_to
                      ? plotDetails.assigned_user_role === "BUILDER"
                        ? builderOptions.find(
                            (option) => option.value === plotDetails.assigned_to
                          )
                        : adminOptions.find(
                            (option) => option.value === plotDetails.assigned_to
                          )
                      : null
                  }
                  isOptionEqualToValue={(option, value) => {
                    if (!value || !value.value) return false;
                    return option.value === value.value;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Builder Name"
                      fullWidth
                    />
                  )}
                  sx={{
                    width: "100%",
                  }}
                />
              </Box>
            )}
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_booked"
                    checked={plotDetails.is_booked}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Already Soldout"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={plotDetails.is_blocked}
                    name="is_blocked"
                    onChange={handleCheckboxChange}
                  />
                }
                label="Blocked Plot"
              />
            </div>
          </div>
        </>
      )}

      <div style={{ marginTop: 20 }}>
        <Button
          variant="outlined"
          href="#contained-buttons"
          color="error"
          onClick={handleCloseToolkit}
        >
          Close
        </Button>
        <Button
          variant="contained"
          href="#contained-buttons"
          color="success"
          style={{ marginLeft: 10 }}
          onClick={handleFormSubmit}
        >
          Save
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={removeUnwantedPlot}
          style={{ marginLeft: 10 }}
        >
          Remove Plot
        </Button>
      </div>
    </div>
  );
};

export default PlotDetailsForm;
