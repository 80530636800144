import "react-calendar/dist/Calendar.css";
import React from "react";
import reactDom from "react-dom/client";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import SmallCalendar from "./smallCalendar/SmallCalendar";

const FollowUpCalendar = () => {
  const locallizer = momentLocalizer(moment);

  const events = [
    {
      start: moment("2024-05-29T18:00:00").toDate(),
      end: moment("2024-05-29T19:00:00").toDate(),
      title: "Client Meeting",
    },
    {
      start: moment("2024-05-29T20:00:00").toDate(),
      end: moment("2024-05-29T21:00:00").toDate(),
      title: "Study Time",
    },
    {
      start: moment("2024-05-31T11:00:00").toDate(),
      end: moment("2024-05-31T12:00:00").toDate(),
      title: "Football Match",
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexBasis: "50%",
        width: "100%",
        height: "auto",
        gap: "10px",
      }}
    >
      <div>
        <SmallCalendar />
      </div>
      <div>
        <Calendar
          localizer={locallizer}
          style={{ height: "450px", width: "700px" }}
          events={events}
        />
      </div>
    </div>
  );
};

export default FollowUpCalendar;
