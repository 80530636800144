import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import DescriptionOverview from "../overview/Overview";
import { IOverviewAccordianProps } from "../../../../../../@types/props/overviewAccodian.props";

const MultipleArrayAccordian: React.FC<IOverviewAccordianProps> = ({
  title,
  mode,
  overview,
  setOverview,
}) => {
  const handleAddMore = () => {
    if (mode === "OVERVIEW") {
      const lastOverview = overview[overview.length - 1];
      if (lastOverview?.project_overview === "") {
        alert("Please fill the previous overview details");
      } else {
        setOverview([...overview, { project_overview: "" }]);
      }
    } else if (mode === "LOCATION") {
      const lastLocationHighlights = overview[overview.length - 1];
      if (lastLocationHighlights?.location_highlight === "") {
        alert("Please fill the previous locaion details");
      } else {
        setOverview([...overview, { location_highlight: "" }]);
      }
    } else if (mode === "HIGHLIGHT") {
      const lastHighlight = overview[overview.length - 1];
      if (lastHighlight?.project_highlight === "") {
        alert("Please fill the previous highlight details");
      } else {
        setOverview([...overview, { project_highlight: "" }]);
      }
    }
  };

  const handleChangeOverview = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    const previousOverview = [...overview];
    previousOverview[index] = {
      ...previousOverview[index],
      [name]: value,
    };
    setOverview(previousOverview);
  };

  const handleRemove = (index: number) => {
    if (mode === "OVERVIEW") {
    if (overview.length === 1) {
      alert("Cannot remove the last item. Please add more items before removing.");
    } else {
      const updatedFacility = overview.filter((_, i) => i !== index);
      setOverview(updatedFacility);
    }
  }
  };
  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {overview.map((overviewItem, index) => (
          <DescriptionOverview
            project_overview={overviewItem.project_overview}
            mode={mode}
            key={index}
            index={index}
            handleAddMore={handleAddMore}
            handleChangeOverview={handleChangeOverview} 
            handleRemove={handleRemove}          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default MultipleArrayAccordian;
