import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import SendIcon from '@mui/icons-material/Send';

const AddReport = ({REPORT_TYPES} : any) => {
  const { setDashboardHeader } = useContext(UIContext);

  useEffect(() => {
    setDashboardHeader("City Wise Report");
  }, [setDashboardHeader]);
  return (
    <div>
      <h3>City Wise Report Generation</h3>
      <Accordion defaultExpanded sx={{ marginTop: "20px" }}>
        <AccordionDetails>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "left",
            }}
          >
            <label>City Name:</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              sx={{ width: "700px", marginBottom: "20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "left",
            }}
          >
            <label>Description:</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              sx={{ width: "700px", marginBottom: "20px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "left",
            }}
          >
            <label>Alternative Report Name:</label>
            <TextField
              id="outlined-basic"
              variant="outlined"
              sx={{ width: "700px", marginBottom: "20px" }}
            />
          </div>
        </AccordionDetails>
        <Button variant='contained' className="btn" sx={{marginLeft: "20px", marginBottom: "20px"}} endIcon={<SendIcon/>}>Submit</Button>
      </Accordion>
    </div>
  );
};

export default AddReport;
