// import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
// import React from "react";
// import { ISelectFieldProps } from "../../../../@types/props/selectField.props";

// const ProjectFormSelectField = ({
//   projectDetails,
//   name,
//   label,
//   handleChange,
//   width,
//   className,
//   inputLabel,
//   options,
// }: ISelectFieldProps) => {
//   return (
//     <div className={className}>
//       <label className="labels">{label}</label>
//       <Box sx={{ width }}>
//         <FormControl fullWidth>
//           <InputLabel id={`${name}-select-label`}>{inputLabel}</InputLabel>
//           <Select
//             labelId={`${name}-select-label`}
//             id={`${name}-select`}
//             value={projectDetails[name] as string}
//             onChange={handleChange}
//             name={name}
//           >
//             {options.map((option) => (
//               <MenuItem key={option.value} value={option.value}>
//                 {option.label}
//               </MenuItem>
//             ))}
//           </Select>
//         </FormControl>
//       </Box>
//     </div>
//   );
// };

// export default ProjectFormSelectField;

import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { ISelectFieldProps } from "../../../../@types/props/selectField.props";

const ProjectFormSelectField = ({
  projectDetails,
  name,
  label,
  handleChange,
  width,
  className,
  inputLabel,
  options,
  required,
}: ISelectFieldProps) => {
  const [showInput, setShowInput] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [statusOptions, setStatusOptions] = useState(options);

  const handleAddStatusClick = () => {
    setShowInput(true);
  };

  const handleNewStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewStatus(event.target.value);
  };

  const handleNewStatusSubmit = () => {
    if (newStatus.trim() !== "") {
      const newOption = { value: newStatus, label: newStatus };
      setStatusOptions([...statusOptions, newOption]);
      const changeEvent = {
        target: { name, value: newStatus },
      };
      handleChange(changeEvent as any, null); // Cast to any to satisfy the type
      setNewStatus("");
      setShowInput(false);
    }
  };

  return (
    <div className={className}>
      <label className="labels">
        {label}
        {required ? (
          <span style={{ color: "red", fontSize: 20, fontWeight: "bold" }}>
            *
          </span>
        ) : null}
      </label>
      <Box sx={{ width }}>
        <FormControl fullWidth>
          <InputLabel id={`${name}-select-label`}>{inputLabel}</InputLabel>
          <Select
            labelId={`${name}-select-label`}
            id={`${name}-select`}
            value={projectDetails[name] as string}
            onChange={(event) => handleChange(event, null)}
            name={name}
          >
            {statusOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {showInput ? (
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={newStatus}
              onChange={handleNewStatusChange}
              placeholder="Enter new one"
            />
            <Button
              onClick={handleNewStatusSubmit}
              variant="contained"
              sx={{ ml: 1 }}
            >
              Add
            </Button>
          </Box>
        ) : (
          <Button onClick={handleAddStatusClick} variant="text" sx={{ mt: 1 }}>
            Add Your Own
          </Button>
        )}
      </Box>
    </div>
  );
};

export default ProjectFormSelectField;
