import React, { useContext, useEffect, useState } from "react";
import UIContext from "../../../../contexts/uiContext/UIContext";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useNavigate } from "react-router-dom";
import { IUser } from "../../../../@types/interface/admin.interface";
import BuilderContext from "../../../../contexts/builderContext/BuilderContext";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";

import { Button, SelectChangeEvent } from "@mui/material";
import { api } from "../../../../utils/api";
import "./employee.css";
import BasicPagination from "../../../shared/basicPagination/BasicPagination";
import Download from "../../../shared/downloadbtn/Download";

const EmployeeManagemnet = () => {
  const navigate = useNavigate();
  const { setDashboardHeader } = useContext(UIContext);
  const { builderDetails } = useContext(BuilderContext);
  const [employeeList, setEmployeeList] = useState<IUser[]>([]);

  const getEmployeeList = async () => {
    const filter = {
      page: 1,
      sortField: "updatedAt",
      builder_object_id: builderDetails?._id || "",
    };
    const response = await api.admin.getEmployeeList(filter);
    setEmployeeList(response);
  };

  const column: any = [
    { field: "full_name", headerName: "Name" },
    { field: "email", headerName: "Email" },
    { field: "phone_number", headerName: "Phone No." },
    { field: "role", headerName: "Role" },
    { field: "last_login_date", headerName: "Last login date" },
    { field: "createdAt", headerName: "Created on" },
  ];

  const handleNavigateToAddEmployee = () => {
    navigate(`/admin/add-employee?cid=${builderDetails?._id}`);
  };

  useEffect(() => {
    setDashboardHeader("Employee Management");
  }, [setDashboardHeader]);

  useEffect(() => {
    getEmployeeList();
  }, []);
  return (
    <div>
      <div className="employee-container">
        <Download />
        <Button
          className="btn"
          style={{ fontSize: "15px" }}
          endIcon={<GroupAddOutlinedIcon />}
          onClick={handleNavigateToAddEmployee}
        >
          Add employee
        </Button>
      </div>
      <div className="ag-theme-alpine" style={{ height: 300 }}>
        <AgGridReact rowData={employeeList} columnDefs={column} />
        {/* <BasicPagination/> */}
      </div>
    </div>
  );
};

// const handleChange = (event: | SelectChangeEvent<string | number | string[]>
//   | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
//     const { target: { value, name}, } = event;
//     setEmployeeList(Object.assign({}, employeeList, { [name]: value }))

// }

export default EmployeeManagemnet;
