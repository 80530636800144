import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { IFrameModalProps } from "../../../../@types/props/iframeModal.props";

const IFrameModal = ({
  dialogOpen,
  handleDialogToogle,
  src,
  project_title,
}: IFrameModalProps) => {
  const iframeUrl = `<iframe title="Project Builder" height={450} width={500} src=${src}></iframe>`;
  return (
    <div>
      <Dialog open={dialogOpen} onClose={handleDialogToogle}>
        <DialogTitle>Iframe View</DialogTitle>
        <DialogContent>
          <DialogContentText>Copy the iframe URL below:</DialogContentText>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <input
              type="text"
              value={iframeUrl}
              readOnly
              style={{ flex: 1, marginRight: "0.5rem" }}
            />
            <Button
              variant="outlined"
              className="green-outlined-button"
              onClick={() => navigator.clipboard.writeText(iframeUrl)}
            >
              Copy
            </Button>
          </div>
          <iframe
            title={project_title}
            height={450}
            width={500}
            src={src}
          ></iframe>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogToogle} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default IFrameModal;
