import React, { useCallback, useEffect, useState } from "react";
import { enquiryColumnDef } from "../../../../../constants/enquiry/EnquiryColDef";
import { AgGridReact } from "ag-grid-react";
import Download from "../../../../shared/downloadbtn/Download";
import { IBooking } from "../../../../../@types/interface/booking.interface";
import { IViewProjectProps } from "../../../../../@types/props/ViewProject.props";
import { api } from "../../../../../utils/api";

const ProjectEnquiry = ({ projectId }: IViewProjectProps) => {
  const [bookingList, setBookingList] = useState<IBooking[]>([]);

  const getEnquiryData = useCallback(async () => {
    const filter = {
      page: 1,
      sortField: "updatedAt",
      project_object_id: projectId,
    };
    const response = await api.booking.getProjectEnquiry(filter);
    setBookingList(response);
  }, [projectId]);

  useEffect(() => {
    getEnquiryData();
  }, [getEnquiryData]);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "right",
          alignItems: "right",
          marginBottom: "10px",
        }}
      >
        <Download />
      </div>
      <div className="ag-theme-alpine" style={{ height: 300, width: "full" }}>
        <AgGridReact rowData={bookingList} columnDefs={enquiryColumnDef} />
        {/* <BasicPagination /> */}
      </div>
    </div>
  );
};

export default ProjectEnquiry;
