import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { ITransportProps } from "../../../../../../@types/props/transport.props";

import AddTaskIcon from "@mui/icons-material/AddTask";
import {
  LANDMARK_TYPE_OPTIONS,
  VEHICLE_OPTION,
} from "../../../../../../constants/landmark/LandMark";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


const Transport = ({
  transport,
  handleAddMore,
  handleChangeNearby,
  index,
  handleRemove
}: ITransportProps) => {
  return (
    <Grid
      container
      spacing={2}
      alignItems={"center"}
      style={{ marginBottom: 10 }}
    >
      <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="transport_type"
            value={transport.transport_type}
            label="Age"
            onChange={(event) => handleChangeNearby(event, index)}
          >
            {LANDMARK_TYPE_OPTIONS.map((option, index) => {
              return (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Place Name"
          name="transport_name"
          value={transport.transport_name}
          onChange={(event) => handleChangeNearby(event, index)}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">By</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="transport"
            value={transport.transport}
            label="Vehicle"
            onChange={(event) => handleChangeNearby(event, index)}
          >
            {VEHICLE_OPTION.map((option, index) => {
              return (
                <MenuItem key={index} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Time (min)"
          name="time"
          value={transport.time}
          onChange={(event) => handleChangeNearby(event, index)}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
        <TextField
          fullWidth
          id="outlined-basic"
          label="Distance (KM)"
          name="distance"
          onChange={(event) => handleChangeNearby(event, index)}
          value={transport.distance}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
        <IconButton
          className="dark-green-button"
          style={{ marginRight: 4 }}
          onClick={handleAddMore}
        >
          <AddTaskIcon />
        </IconButton>
        <IconButton
          className="dark-red-button"
          style={{ marginRight: 4, color: "red" }}
          onClick={() => handleRemove(index)}
        >
          <DeleteOutlineIcon /> 
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Transport;
