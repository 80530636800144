import { IProject, IProjectAminity } from "../../@types/interface/Projects";
import { IHighlight } from "../../@types/interface/highlight.interface";
import { ILocationsHighlights } from "../../@types/interface/locationHighlights.interface";
import { IOverview } from "../../@types/interface/overview.interface";
import { ITransport } from "../../@types/interface/transport.interface";

export const defaultProjectForm: IProject = {
  project_name: "",
  project_type: [],
  project_category: "",
  root_project_object_id: null,
  approved_by: [],
  bank_partners: [],
  state: "",
  formatted_address: "",
  city_facing: "",
  highlights: [],
  highway_facing: "",
  plot_landmark: "",
  price_per_sq: 0,
  price_unit: "",
  total_sq_feet: 0,
  description: "",
  average_rating: 0,
  min_price: 0,
  max_price: 0,
  price_per_unit: 0,
  loan_upto: 0,
  downpayment: 0,
  no_of_ratings: 0,
  status: "DRAFT",
  emi_starts: 0,
  layout_image: "",
  location_google_link: "",
  total_plots: 0,
  has_reslling_plots: false,
  has_new_plots: false,
  has_landlord_plots: false,
  is_bank_loan_available: false,
  has_bank_partner: false,
  is_revenue_sharing: "",
  emi_months: 0,
  is_emi_option: false,
  project_highlights: [],
  location_highlights: "",
  construction_status: "",
  construction_date: "",
  launching_date: "",
  project_future_extended_area: "",
  highlight_points: [],
  roads: [],
  road_size: 0,
  photos: [],
  videos: [],
  brochure: "",
  authority: "",
  offers: [],
  max_plot_size: 0,
  min_plot_size: 0,
  rera_number: "",
  tlp_number: "",
};

export const defaultNearbyForm: ITransport = {
  transport_name: "",
  transport_type: "STATION",
  distance: 0,
  project_object_id: "",
  time: 0,
  transport: "",
};

export const projectOverviewForm: IOverview = {
  project_overview: "",
};

export const projectAttractionForm: IHighlight = {
  project_highlight: "",
};

export const locationHighlightsForm: ILocationsHighlights = {
  location_highlight: "",
};

export const projectFacilityForm: IProjectAminity = {
  facility_details: "",
};
