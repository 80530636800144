import { Button } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import BuilderContext from '../../../contexts/builderContext/BuilderContext'

const LeadStatusCellRenderer = () => {
    const navigate = useNavigate()
    const {builderDetails} = useContext(BuilderContext)

    const handleRouteToLeadStatus = () => {
        navigate(`/admin/lead-status-details?cid=${builderDetails?._id}`)
    }
  return (
    <Button variant="outlined" className='green-outlined-button' onClick={handleRouteToLeadStatus}>See Details</Button>
  )
}

export default LeadStatusCellRenderer