import { useState, useContext, ChangeEvent, useCallback } from "react";
import Paper from "@mui/material/Paper";
import image from "../../../../assets/images/login-image.svg";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import incornersLogo from "../../../../assets/images/logo-transparent.png"
import { api } from "../../../../utils/api";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../../../contexts/authContext/authContext";
import { ROLES } from "../../../../constants/roles/Roles";
import ConfirmModal from "../otpLogin/otpConfirmationModel";
import logo from "../../../../assets/images/logo.svg";
import LockOpenIcon from '@mui/icons-material/LockOpen';

import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import "./login.css";

const paperStyles = {
  width: "390px",
  height: "auto",
  alignItems: "center",
  padding: "20px",
};

export default function Login() {
  const { setUser } = useContext(AuthContext);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [open, setOpen] = useState(false);
  const [originalOtp, setOriginalOtp] = useState("");
  const [userResponse, setUserResponse] = useState<any>(null);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    let formattedPhoneNumber = event.target.value;

    formattedPhoneNumber = formattedPhoneNumber.replace(/\D/g, "");

    if (formattedPhoneNumber.startsWith("91")) {
      formattedPhoneNumber = formattedPhoneNumber.slice(2);
    }

    formattedPhoneNumber = "+91" + formattedPhoneNumber;

    setPhoneNumber(formattedPhoneNumber);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const sendOtp = async (phone_number: string) => {
    try {
      const otp_response = await api.auth.getOtp({
        phone_no: phone_number,
      });
      console.log("Checkning that otp response --->>>>", otp_response);
      if (otp_response) {
        setOriginalOtp(otp_response);
        setOpen(true);
      }
    } catch (error) {
      console.error("Error requesting OTP:", error);
    }
  };

  const handleConfirm = async () => {
    if (phoneNumber.length !== 13) {
      alert("Phone number must be a 10-digit number.");
      return;
    }
    try {
      const loginData = {
        userId: phoneNumber,
        password: password,
      };

      const response = await api.auth.loginAdmin(JSON.stringify(loginData));
      if (response) {
        setUserResponse(response);
        console.log("Hiting that response than go for more---->");
        sendOtp(response.phone_number);
      }
    } catch (error) {
      console.error("Error while login sending payload", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOtpConfirm = useCallback(() => {
    if (userResponse) {
      setUser(userResponse);
      switch (userResponse.role) {
        case ROLES.super_admin:
          navigate("/");
          break;
        case ROLES.telecaller:
          navigate(`/admin?cid=${userResponse.builder_object_id}`);
          break;
        case ROLES.project_manager:
          navigate(`/admin?cid=${userResponse.builder_object_id}`);
          break;
        case ROLES.channel_partner:
          navigate(`/admin?cid=${userResponse.builder_object_id}`);
          break;
        case ROLES.builder_admin:
          navigate(`/admin?cid=${userResponse.builder_object_id}`);
          break;
        case ROLES.landlord:
          navigate("/landlord");
          break;
        case ROLES.reseller:
          navigate("/reseller");
          break;
        case ROLES.marketer:
          navigate("/marketer");
          break;
        default:
          console.error("Unknown role:", userResponse.role);
      }
    }
  }, [navigate, setUser, userResponse]);

  return (
     <div
       className="login-container"
       style={{
         width: "full",
         height: "100vh",
         backgroundColor: "white",
         margin: "0 auto",
        
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
       }}
     >
       <div
         className="login_form"
         style={{
           width: "68%",
           height: "auto",
           margin: "auto",
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
           border: "2px",
           borderRadius: "15px",
           gap: "20px"
         }}
       >
         <div className="login_img" >
           <img src={image} alt="" />
         </div>
         <div className="login_content">
          <div className="incorners-logo-container">
            <img src={incornersLogo} alt="incorners" style={{width: "40%"}}/>
          </div>
           
           <Paper elevation={0} style={paperStyles} id="form-paper">
             <div className="form">
               <div
                className="login_heading"
                 style={{
                   display: "flex",
                   alignItems: "center",
                   flexDirection: "column",
                 }}
               >
                 <h1>Welcome <span style={{color: "#ff9037", fontWeight: "bold" }}>Back</span></h1>
                <p style={{margin: 0, fontSize: "15px"}}>Please enter your login credentials to continue</p>
               </div>
               {/* <TextField
                 type="text"
                 label="Phone Number"
                 placeholder="Enter your email or phone number"
                 sx={{ marginTop: 2, marginLeft: 2, width: "90%" }}
                 value={phoneNumber}
                 onChange={handlePhoneNumberChange}
                 endAdornment={
                               <InputAdornment position="end">
                                 <IconButton
                                   aria-label="toggle password visibility"
                                   edge="end"
                                 >
                                <LocalPhoneIcon />
                              </IconButton>
                             </InputAdornment>
                          }
                 required
               /> */}
               {/* username section */}
          <FormControl  variant="outlined" className="input-field-with-icon">
             <InputLabel htmlFor="outlined-adornment-password">
               Phone Number
             </InputLabel>
             <OutlinedInput
               id="outlined-adornment-password"
               type="text"
               endAdornment={
                 <InputAdornment position="end">
                   <IconButton
                    aria-label="toggle password visibility"
                     edge="end"
                   >
                     <LocalPhoneIcon />
                   </IconButton>
                 </InputAdornment>
               }
               label="Username"
               value={phoneNumber}
               onChange={handlePhoneNumberChange}
               required
            />
           </FormControl>

            {/* password section */}
           <FormControl variant="outlined"  className="input-field-with-icon">
             <InputLabel htmlFor="outlined-adornment-password">
               Password
             </InputLabel>
             <OutlinedInput
               id="outlined-adornment-password"
               type={showPassword ? "text" : "password"}
               endAdornment={
                 <InputAdornment position="end">
                   <IconButton
                     aria-label="toggle password visibility"
                     onClick={handleClickShowPassword}
                     onMouseDown={handleMouseDownPassword}
                     onMouseUp={handleMouseUpPassword}
                     edge="end"
                   >
                     {showPassword ? <VisibilityOff /> : <Visibility />}
                   </IconButton>
                 </InputAdornment>
               }
               label="Password"
               value={password}
               onChange={handlePasswordChange}
               required
             />
        </FormControl>
               {/* <TextField
                 label="Password"
                 type="password"
                 placeholder="Enter your password"
                 sx={{ marginTop: 2, marginLeft: 2, width: "90%" }}
                 value={password}
                 onChange={handlePasswordChange}
                 required
              /> */}
               <p
                 style={{
                   textAlign: "right",
                   marginRight: "18px",
                   marginTop: "15px",
                 }}
               >
                 <a style={{ textDecoration: "none" }} href="#">
                   Forgot password?
                 </a>
               </p>
               <Button
                 variant="contained"
                 className="btn"
                 sx={{
                   marginLeft: 2,
                   marginTop: 2,
                   width: "90%",
                   height: "50px",
                   fontFamily: "Railway, sans-serif",
                  
                 }}
                 onClick={handleConfirm}
               >
                 Next
               </Button>
               <div className="sign-up-link-container">
            <p className="sign-up-link">Don't have any account? <span ><a href="#" className="link">Sign Up</a></span></p>
             </div>
             </div>
           </Paper>
         </div>
       </div>
       <ConfirmModal
         open={open}
         handleClose={handleClose}
         handleConfirm={handleOtpConfirm}
         originalOTP={originalOtp}
       />
     </div>
    
    // <div className="login-container">
    //   <div className="image-section">
    //     <div className="login-image">
    //       <img src={image} alt="" style={{ width: "70%" }} />
    //     </div>
    //     <h1>Get Things Done Easily!</h1>
    //   </div>
    //   <div className="login-form-section">
    //     <Paper className="form" elevation={0}>
    //       <div className="brand-logo">
    //         <img src={logo} alt="" style={{ width: "50%" }} />
    //       </div>

    //       <h1
    //         style={{ color: "#333333", fontWeight: "bold" }}
    //         className="welcome-text"
    //       >
    //         Welcome{" "}
    //         <span style={{ color: "orange", fontWeight: "bold" }}>Back</span>
    //       </h1>
    //       <p className="sub-text">
    //         Please enter your login credentials to continue.
    //       </p>

    //       {/* username section */}
    //       <FormControl sx={{ m: 1, width: "350px" }} variant="outlined">
    //         <InputLabel htmlFor="outlined-adornment-password">
    //           Phone Number
    //         </InputLabel>
    //         <OutlinedInput
    //           id="outlined-adornment-password"
    //           type="text"
    //           endAdornment={
    //             <InputAdornment position="end">
    //               <IconButton
    //                 aria-label="toggle password visibility"
    //                 edge="end"
    //               >
    //                 <LocalPhoneIcon />
    //               </IconButton>
    //             </InputAdornment>
    //           }
    //           label="Username"
    //           value={phoneNumber}
    //           onChange={handlePhoneNumberChange}
    //           required
    //         />
    //       </FormControl>

    //       {/* password section */}
    //       <FormControl sx={{ m: 1, width: "350px" }} variant="outlined">
    //         <InputLabel htmlFor="outlined-adornment-password">
    //           Password
    //         </InputLabel>
    //         <OutlinedInput
    //           id="outlined-adornment-password"
    //           type={showPassword ? "text" : "password"}
    //           endAdornment={
    //             <InputAdornment position="end">
    //               <IconButton
    //                 aria-label="toggle password visibility"
    //                 onClick={handleClickShowPassword}
    //                 onMouseDown={handleMouseDownPassword}
    //                 onMouseUp={handleMouseUpPassword}
    //                 edge="end"
    //               >
    //                 {showPassword ? <VisibilityOff /> : <Visibility />}
    //               </IconButton>
    //             </InputAdornment>
    //           }
    //           label="Password"
    //           value={password}
    //           onChange={handlePasswordChange}
    //           required
    //         />
    //       </FormControl>
    //       <div className="forgot-password-link-container">
    //         <p
    //           style={{
    //             textAlign: "right",
    //             marginTop: "15px",
    //           }}
    //         >
    //           <a style={{ textDecoration: "none", fontWeight: "400" }} href="#">
    //             Forgot password?
    //           </a>
    //         </p>
    //       </div>

    //       <Button
    //         variant="contained"
    //         className="button-orange"
           
    //         onClick={handleConfirm}
    //       >
    //         Login
           
    //       </Button>
    //         <div className="sign-up-link-container">
    //         <p className="sign-up-link">Don't have any account? <span ><a href="#" className="link">Sign Up</a></span></p>
    //         </div>
         

    //      <div className="copyright-section">
    //           @ 2024 copyright all reserved
    //      </div>
    //     </Paper>
    //   </div>
    // </div>
  );
}
