// export const BankPartnersOptions = [
//   { label: "State Bank of India", value: "State Bank of India" },
//   { label: "HDFC Bank", value: "HDFC Bank" },
//   { label: "ICICI Bank", value: "ICICI Bank" },
//   { label: "Punjab National Bank", value: "Punjab National Bank" },
//   { label: "Axis Bank", value: "Axis Bank" },
//   { label: "Kotak Mahindra Bank", value: "Kotak Mahindra Bank" },
//   { label: "Bank of Baroda", value: "Bank of Baroda" },
//   { label: "Canara Bank", value: "Canara Bank" },
//   { label: "Union Bank of India", value: "Union Bank of India" },
//   { label: "IndusInd Bank", value: "IndusInd Bank" },
// ];

export const BankPartnersOptions = [
  "SBI",
  "Axis",
  "HDFC",
  "ICICI",
  "Bank of India",
  "Punjab National Bank",
  "Canara Bank",
];
