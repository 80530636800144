import React, { useContext, useEffect, useState } from "react";
import DataGrid from "../../../../../shared/dataGrid/DataGrid";
// import { FollowupColDefs } from "../../../../../../constants/folllowUp/followupColDefs";
import { Button } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import BuilderContext from "../../../../../../contexts/builderContext/BuilderContext";
import { IProject } from "../../../../../../@types/interface/Projects";
import UIContext from "../../../../../../contexts/uiContext/UIContext";
import AuthContext from "../../../../../../contexts/authContext/authContext";
import { IFollowUp } from "../../../../../../@types/interface/followup.interface";
import { api } from "../../../../../../utils/api";
import DataCellRenderer from "../../../../../shared/cellRenderer/DataCellRenderer";

const FollowUpDetails = ({ data }: { data: any }) => {
  const navigate = useNavigate();
  const handleNavigateToFollowUpForm = () => {
    navigate(`/admin/add-follow-up?cid=${builderDetails?._id}&eid=${data}`);
  };
  const { user } = useContext(AuthContext);
  const { setDashboardHeader } = useContext(UIContext);
  const { builderDetails } = useContext(BuilderContext);
  const [followupList, setFollowupList] = useState<IFollowUp[]>([]);
  const queryParams = new URLSearchParams(window.location.search);
  const enquiryId = queryParams.get("eid");

  const getFollowUp = async () => {
    const filter = {
      followby_object_id: user?._id as string,
      enquiry_object_id: enquiryId as string,
    };
    const response = await api.followUp.getFollowUp(filter);
    setFollowupList(response);
  };
  const column: any = [
    { field: "followUp_date", headerName: "Follow Up Date" },
    { field: "followUp_note", headerName: "Follow Up Note" },
    { field: "followUp_time", headerName: "Follow Up Time" },
  ];

  useEffect(() => {
    setDashboardHeader("Follow up details");
    // console.log("data check --->",data?._id)
  }, [setDashboardHeader]);

  useEffect(() => {
    getFollowUp();
  }, []);
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
          marginBottom: "20px",
        }}
      >
        <Button
          variant="contained"
          className="btn"
          endIcon={<AddCircleOutlineIcon />}
          onClick={handleNavigateToFollowUpForm}
        >
          Add Follow Up
        </Button>
      </div>
      <DataGrid colDefs={column} rowData={followupList} key={0} />
    </div>
  );
};

export default FollowUpDetails;
