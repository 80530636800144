export const LANDTYPES_TYPE_OPTIONS: { label: string; value: string }[] = [
    {
      label: "Residential",
      value: "RESIDENTIAL",
    },
    {
      label: "Commercial",
      value: "COMMERCIAL",
    },
    {
      label: "Industrial",
      value: "INDUSTRIAL",
    },
    {
      label: "Farm Land",
      value: "FARMLAND",
    },
    

  ];
  